import React, { Fragment, ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, LargeHeading, SidebarContentBox, SidebarContentListBox } from "src/components";
import { localize } from "src/l10n";
import { toggleGreyBackground, setVisage2FullscreenMode, UnstyledButton } from "src/ui";
import { scrollToTop } from "src/utils";
import { WikiListState, WikiListViewStateProps } from "./WikiListView.types";
import { PrioritizedWikiList, WikiList } from "src/components";
import { Icon, Stack, StackItem } from "@fluentui/react";
import { fetchRecentlyVisitedWikiArticlesAsync } from "src/api";
import Axios from "axios";
import { useRealtime } from "src/hooks";
import { SpintrTypes } from "src/typings";
import { fetchFavorites } from "src/favorites";

function WikiListView(): ReactElement {
    const [state, setState] = useState<WikiListState>({
        drawerOpen: false,
        myRecent: [],
        myRecentLoading: true,
        favoritedWikis: [],
        favoritedWikisLoading: true,
        teamRecent: [],
        teamRecentLoading: true,
    });

    const { subscribe, unsubscribe } = useRealtime();
    const dispatch = useDispatch();
    const { isSmallViewMode } = useSelector<Spintr.AppState, WikiListViewStateProps>(
        (appState) => ({ isSmallViewMode: appState.ui.isSmallViewMode })
    );

    const onDrawerDismissed = useCallback(() => setState((prevState) => ({
        ...prevState,
        drawerOpen: false,
    })), [setState]);

    const onOpenDrawerClicked = useCallback(() => setState((prevState) => ({
        ...prevState,
        drawerOpen: true,
    })), [setState]);

    const fetchWikiFavorites = () => {
        fetchFavorites(undefined, [SpintrTypes.UberType.Wiki, SpintrTypes.UberType.WikiArticle])
            .then((wikis) => setState((prevState) => ({
                ...prevState,
                favoritedWikis: wikis.data.map((wiki) => ({
                    key: `favorited-${wiki.id}`,
                    iconName: "volume-low-1",
                    text: wiki.title,
                    url: wiki.url,
                    wikiId: wiki.id
                })),
                favoritedWikisLoading: false,
            })))
            .catch((err) => {
                console.error(err);
                setState((prevState) => ({
                    ...prevState,
                    favoritedWikisLoading: false,
                }));
            });
    }

    useEffect(() => {
        dispatch(toggleGreyBackground(true));

        const cancelTokenSource = Axios.CancelToken.source();

        fetchRecentlyVisitedWikiArticlesAsync("me", cancelTokenSource.token)
            .then((articles) => setState((prevState) => ({
                ...prevState,
                myRecent: articles.map((article) => ({
                    key: `me-${article.id}`,
                    iconName: "clock",
                    text: article.title,
                    url: article.url,
                })),
                myRecentLoading: false,
            })))
            .catch((err) => {
                console.error(err);
                setState((prevState) => ({
                    ...prevState,
                    myRecentLoading: false,
                }));
            });

        fetchWikiFavorites();

        fetchRecentlyVisitedWikiArticlesAsync("team", cancelTokenSource.token)
            .then((articles) => setState((prevState) => ({
                ...prevState,
                teamRecent: articles.map((article) => ({
                    key: `team-${article.id}`,
                    iconName: "clock",
                    text: article.title,
                    url: article.url,
                })),
                teamRecentLoading: false,
            })))
            .catch((err) => {
                console.error(err);
                setState((prevState) => ({
                    ...prevState,
                    teamRecentLoading: false,
                }));
            });

        return () => {
            scrollToTop();
            dispatch(toggleGreyBackground(false));
            cancelTokenSource.cancel();
        };
    }, [dispatch, setState]);

    const onFavoriteToggled = useCallback((msg) => {
        if (msg.objectType !== SpintrTypes.UberType.Wiki &&
            msg.objectType !== SpintrTypes.UberType.WikiArticle) {
            return;
        }

        fetchWikiFavorites();
    }, [setState]);

    useEffect(() => {
        subscribe("Object:Favorite", onFavoriteToggled);

        return () => unsubscribe("Object:Pin", onFavoriteToggled);
    }, [subscribe, unsubscribe]);

    const sidebarContent = (
        <Fragment>
            <SidebarContentListBox
                className="sidebar-box"
                items={state.myRecent}
                title={localize("RECENTLY_VISITED")} />
            <SidebarContentListBox
                className="sidebar-box pins-box"
                items={state.favoritedWikis}
                title={localize("GROUP_PINS")} />
            <SidebarContentListBox
                className="sidebar-box"
                items={state.teamRecent}
                title={localize("POPULAR_WIKIS_TEAM")} />
        </Fragment>
    );

    const sidebar = isSmallViewMode
        ? (
            <Drawer open={state.drawerOpen} position="end">
                <DrawerHeader>
                    <DrawerHeaderTitle action={
                        <UnstyledButton
                            className="close-button"
                            onClick={onDrawerDismissed}
                            title={localize("Stang")}
                        >
                            <Icon iconName="ChromeClose" />
                        </UnstyledButton>
                    }>
                        {localize("Genvagar")}
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <div className="WikiListView-sidebar">
                        {sidebarContent}
                    </div>
                </DrawerBody>
            </Drawer>
        )
        : (
            <StackItem className="WikiListView-sidebar">
                {sidebarContent}
            </StackItem>
        );

    const openDrawerButton = !isSmallViewMode ? null : (
        <UnstyledButton
            className="open-drawer-button"
            onClick={onOpenDrawerClicked}
            title={localize("Genvagar")}
        >
            <Icon iconName="GlobalNavButton" />
        </UnstyledButton>
    );

    return (
        <div id="WikiListView">
            <Stack className="WikiListView-stack" horizontal={true}>
                <StackItem className="WikiListView-main">
                    <header className="WikiListView-header">
                        <LargeHeading color="contentDark">{localize("Wikis")}</LargeHeading>
                        {openDrawerButton}
                    </header>
                    {/* Tag List */}
                    <section className="WikiListView-prioritized">
                        <PrioritizedWikiList />
                    </section>
                    <WikiList />
                </StackItem>
                {sidebar}
            </Stack>
        </div>
    );
}

export default WikiListView;
