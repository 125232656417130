import React, { useCallback, useMemo, useState } from 'react';
import "./MessageActions.scss";
import { ActionMenu, Label, UnstyledButton, setReactionPickerConfig } from 'src/ui';
import { localize } from 'src/l10n';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { connect, useDispatch } from 'react-redux';
import { IApplicationState } from 'src/spintr/reducer';
import { IReactionPickerConfig } from 'src/ui/reducer';
import { Style } from 'src/ui/helpers';
import { pinMessageRealtime, removeMessageRealtime, setComposerEditMessage, setComposerReplyMessage, unpinMessageRealtime } from 'src/chat/redux';
import { setConfirmPopup } from 'src/popups/actions';
import api from 'src/spintr/SpintrApi';
import moment from 'moment';

interface IProps {
    isSmallViewMode: boolean;
    currentUserId: number;
    currentUserName: string;
    conversation: Spintr.IConversation;
    message: Spintr.IChatMessage;
    reactionPickerVisible?: boolean;
}

interface IState {
    menuIsOpen: boolean;
}

const MessageActions = (props: IProps) => {
    const dispatch = useDispatch();

    const [state, setState] = useState<IState>({
        menuIsOpen: false
    });

    const onReactionButtonHover = useCallback(() => {
        setTimeout(() => {
            if (props.isSmallViewMode || !isHovering()) {
                return;
            }

            dispatch(setReactionPickerConfig(getReactionPickerConfig(true)));
        }, 200);
    }, []);

    const onReactionButtonBlur = useCallback(() => {
        setTimeout(() => {
            if (props.isSmallViewMode || isHovering()) {
                return;
            }

            if (!document.querySelector(".ReactionPicker")?.querySelector(":hover")) {
                dispatch(setReactionPickerConfig(undefined));
            }
        }, 200);
    }, []);

    const onReactionButtonClick = useCallback(() => {
        if (!props.isSmallViewMode) {
            return;
        }

        dispatch(setReactionPickerConfig(getReactionPickerConfig(true)));
    }, []);

    const getReactionPickerConfig = (isVisible?: boolean) => {
        return {
            isVisible,
            elementId: "like-action-" + props.message.id,
            uberId: props.message.id,
            yOffset: -50,
            xOffset: props.message.user.id === props.currentUserId ? -150 : -50,
            identity: {
                id: props.currentUserId,
                name: props.currentUserName
            },
            sendToMixpanel: false,
            isChat: true
        }
    }

    const isHovering = () => {
        return document.querySelector(".like-action-" + props.message.id)?.querySelector(":hover");
    }

    const readByUsers: Spintr.IConversationParticipant[] = useMemo(() => {
        const result: any[] = [];

        if (!props.conversation) {
            return result;
        }
    
        for (const [key, value] of Object.entries(props.conversation.lastRead)) {
            if (value >= props.message.id) {
                const participant = props.conversation?.participants.find(x => x.id.toString() === key);
    
                if (participant) {
                    result.push(participant);
                }
            }
        }
    
        return result;
    }, [props.conversation]);

    const onReplyClick = useCallback(() => {
        dispatch(setComposerReplyMessage(props.message, props.message.conversationId));
    }, []);

    const onEditClick = useCallback(() => {
        dispatch(setComposerEditMessage(props.message, props.message.conversationId));
    }, []);

    const onDeleteClick = useCallback(() => {
        dispatch(setConfirmPopup({
            isOpen: true,
            title: localize("RaderaInnehall"),
            message: localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase()),
            onConfirm: () => {
                dispatch(removeMessageRealtime(props.message.id));
                api.delete("/api/v1/messages?ids=" + props.message.id).then(() => { }).catch(() => { });
            }
        }));
    }, []);

    const onPinClick = useCallback(() => {
        const url = "/api/v1/messages/" + props.message.id + "/pin";

        if (props.message.isPinned) {
            api.delete(url).then(() => {}).catch(() => {});
            dispatch(unpinMessageRealtime(props.message));
        } else {
            api.post(url).then(() => {}).catch(() => {});
            dispatch(pinMessageRealtime(props.message));
        }
    }, [props.message.isPinned]);

    return (
        <div className={[
            "MessageActions",
            props.reactionPickerVisible || state.menuIsOpen ? "visible" : "hidden"
        ].join(" ")}>
            <UnstyledButton
                id={"like-action-" + props.message.id}
                className={"like-action-" + props.message.id}
                onMouseEnter={onReactionButtonHover}
                onMouseLeave={onReactionButtonBlur}
                onClick={onReactionButtonClick}>
                <Visage2Icon icon="emoji-happy" size="small" />
            </UnstyledButton>
            {!props.message.parentId && (
                <UnstyledButton title={localize("Svara")} onClick={onReplyClick}>
                    <Visage2Icon icon="redo" size="small" />
                </UnstyledButton>
            )}
            <ActionMenu
                iconColor={Style.getHexFromSpintrColor("dark-grey")}
                icon="more-circle"
                onMenuOpened={() => setState((s) => ({
                    ...s,
                    menuIsOpen: true
                }))}
                canReport={props.message.user.id !== props.currentUserId}
                objectId={props.message.id}
                reportType={2}
                onMenuDismissed={() => setState((s) => ({
                    ...s,
                    menuIsOpen: false
                }))}
                categories={[{
                    items: [
                        ...(
                            props.conversation?.group ?
                            [{
                                text: localize(props.message.isPinned ? "Slapp" : "Fast"),
                                onClick: onPinClick
                            }] :
                            []
                        ),
                        ...(
                            props.message.user.id === props.currentUserId ?
                                [
                                    {
                                        text: localize("Redigera"),
                                        onClick: onEditClick
                                    },
                                    {
                                        text: localize("TaBort"),
                                        onClick: onDeleteClick
                                    }
                                ] :
                                []
                        ),
                        {
                            text: readByUsers.length === props.conversation?.participants.length ?
                                localize("READ_BY_ALL") :
                                localize("READ_BY") + " " + readByUsers.length + "/" + (props.conversation?.participants.length || 0),
                            onClick: () => {},
                            subMenuProps: {
                                items: readByUsers.map((x) => {
                                    return {
                                        key: x.id,
                                        text: x.name,
                                        onClick: () => {},
                                    }
                                })
                            }
                        }
                    ],
                }]}
            />
            <Label size="body-3" color="dark-grey">{moment(props.message.date).format("LT")}</Label>
        </div>
    )
}

export default connect(
    (state: IApplicationState, props: IProps): IProps => ({
        ...props,
        reactionPickerVisible: state.ui.reactionPickerConfig?.uberId === props.message.id
    })
)(MessageActions);

