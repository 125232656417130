import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setVisage2FullscreenHeaderVisible, setVisage2FullscreenMode } from "src/ui/actions";
import Headroom from "react-headroom";
import { CommandBar } from "@fluentui/react";
import SpintrSearch from "../SpintrList/SpintrSearch";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./Visage2FullScreenView.scss";

interface IProps {
    disableSearch?: boolean;
    optionItems?: any;
    buttons?: any[];
    farItems?: any[];
    actionMenu?: any;
    optionItemsTitle?: string;
    searchEvent?: any;
}

const getHeaderWidth = () => {
    const elements = document.getElementsByClassName("headroom-wrapper");

    if (!!elements && elements.length > 0) {
        const element = elements[0];

        if (!!element) {
            return element.clientWidth;
        }
    }
}

const Visage2FullScreenHeader = (props: IProps) => {
    const [searchText, setSearchText] = useState("");
    const [headerWidth, setHeaderWidth] = useState(getHeaderWidth());
    const dispatch = useDispatch();

    const handleResize = () => {
        const newHeaderWidth = getHeaderWidth();

        if (headerWidth !== newHeaderWidth) {
            setHeaderWidth(newHeaderWidth);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        dispatch(setVisage2FullscreenHeaderVisible(true));

        return () => {
            window.addEventListener("resize", handleResize, false);
            dispatch(setVisage2FullscreenHeaderVisible(false));
        }
    }, []);

    return (
        <div className="Visage2FullScreenHeader">
            <div className="Visage2FullScreenHeader-content">
                <CommandBar
                    className={
                        "spintr-list-command-bar-header" + (props.disableSearch ? "" : " CommandBar-GrowSearch")
                    }
                    items={[
                        ...(!props.disableSearch
                            ? [
                                {
                                    key: "search",
                                    onRender: () => {
                                        return (
                                            <SpintrSearch
                                                classes="searchBox"
                                                value={searchText}
                                                onChange={(event: React.ChangeEvent, searchText: string) => {
                                                    setSearchText(searchText);
                                                    
                                                    if (!!props.searchEvent) {
                                                        props.searchEvent(event, searchText);
                                                    }
                                                }} />
                                        )
                                    },
                                },
                            ]
                            : []),
                        ...(props.optionItems
                            ? [
                                {
                                    key: "alternativ",
                                    name: props.optionItemsTitle ?? localize("Alternativ"),
                                    subMenuProps: {
                                        ...props.optionItems,
                                        isBeakVisible: false
                                    },
                                },
                            ]
                            : []),
                        ...(props.buttons ? [...props.buttons.map((b: any) => {
                            return {
                                ...b,
                                onRenderIcon: () => {
                                    if (!b.iconProps) {
                                        return null;
                                    }

                                    return (
                                        <Visage2Icon
                                            icon={b.iconProps.iconName}
                                            size={b.iconProps.iconName.toLowerCase() === "add" ? "big" :"medium"}
                                            color={b.className.indexOf("commandBarAddButton") > -1 ? "white" : "light-blue"} />
                                    )
                                }
                            }
                        })] : []),
                    ]}
                    farItems={[
                        ...(props.farItems
                            ? props.farItems
                            : [
                                ...(props.actionMenu
                                    ? [
                                        {
                                            key: "actionMenu",
                                            text: "",
                                            iconProps: { iconName: "More" },
                                            onRender: () => {
                                                return null;
                                                //return renderActionMenu();
                                            },
                                        },
                                    ]
                                    : []),
                            ]),
                    ]}
                />
            </div>
        </div>
    )
}

export default Visage2FullScreenHeader;