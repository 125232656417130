import React, { CSSProperties, ReactElement, useCallback, useMemo } from "react";
import { TimelineCategoryProps } from "./TimelineCategory.types";
import { TimelineRow } from "../TimelineRow";
import { UnstyledButton } from "src/ui";
import { CaptionBody, SmallBody } from "src/components/Text";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Conditional } from "src/components/Conditional";
import classNames from "classnames";
import { localize } from "src/l10n";

function TimelineCategory(props: TimelineCategoryProps): ReactElement {
    const {
        axisWidth,
        category,
        cumulativeIndex,
        offset,
        onBarClick,
        onCategoryClick,
        onItemDurationChange,
        onDisplayedItemsChange,
        timelineDuration,
        timelineSize,
        todayTime
    } = props;
    const items = category.items || [];

    const onAxisClicked = useCallback(
        () => onCategoryClick?.(category),
        [category, onCategoryClick],
    );

    const onViewMoreClicked = useCallback(
        () => onDisplayedItemsChange?.(
            category,
            category.itemsDisplayed >= (category.items || []).length
                ? Math.min((category.items || []).length, 3)
                : (category.items || []).length),
        [category, onDisplayedItemsChange],
    );

    const categoryStyle = useMemo<CSSProperties>(() => ({
        top: `${offset}px`,
    }), [axisWidth, offset]);

    const labelStyle = useMemo<CSSProperties>(() => ({
        height: `52px`,
        top: `${offset}px`,
        width: `${axisWidth}px`
    }), [axisWidth, offset]);

    return (
        <div
            className={classNames("TimelineCategory", {
                "expanded": category.expanded,
                "showingAll": items.length <= category.itemsDisplayed,
            })}
            style={categoryStyle}
        >
            <div className="TimelineCategory-axisLabel" style={labelStyle}>
                <UnstyledButton className="TimelineCategory-axisButton" onClick={onAxisClicked}>
                    <SmallBody color="contentDark" weight="medium">
                        {category.name}
                    </SmallBody>
                    <Visage2Icon className="TimelineCategory-axisIcon" color="spintrGrey" icon="arrow-up-2" size="medium" />
                </UnstyledButton>
            </div>
            <Conditional condition={category.expanded}>   
                <div className="TimelineCategory-items">
                    {items.slice(0, category.itemsDisplayed).map((item, index) => (
                        <TimelineRow
                            axisWidth={axisWidth}
                            item={item} 
                            key={index}
                            onBarClick={onBarClick}
                            onItemDurationChange={onItemDurationChange}
                            rowIndex={index}
                            style={{
                                height: "50px",
                                top: `${52 + index * 50}px`,
                                backgroundColor: (cumulativeIndex + 1 + index) % 2 === 0
                                    ? undefined
                                    : "rgba(0, 0, 0, 0.04)",
                            }}
                            timelineDuration={timelineDuration}
                            timelineSize={timelineSize}
                            todayTime={todayTime} />
                    ))}
                </div>
                <Conditional condition={items.length > 3}>
                    <div
                        className="TimelineCategory-viewMore"
                        style={{
                            height: "40px",
                            top: `${52 + category.itemsDisplayed * 50}px`,
                        }}
                    >
                        <UnstyledButton
                            className="TimelineCategory-viewMore-button"
                            onClick={onViewMoreClicked}
                            style={{
                                width: `${axisWidth}px`
                            }}
                        >
                            <CaptionBody color="blue" weight="medium">
                                {localize(items.length >= category.itemsDisplayed
                                    ? "VisaMindre"
                                    : "VisaFler")}
                            </CaptionBody>

                            <Visage2Icon
                                className="TimelineCategory-viewMore-icon"
                                color="visageMidBlue"
                                icon="arrow-up-2"
                                size="small" />
                        </UnstyledButton>
                    </div>
                </Conditional>
            </Conditional>
        </div>
    );
}

export default TimelineCategory;
