import { AxiosError } from "axios";
import { Action } from "redux";

export enum PlannerActionTypes {
    PlannerItemCreated = "PLANNER_ITEM_CREATED",
    PlannerItemDeleted = "PLANNER_ITEM_DELETED",
    PlannerItemUpdated = "PLANNER_ITEM_UPDATED",

    QueryPlannerItems = "PLANNER_ITEM_QUERY",
    PlannerItemQueryPending = "PLANNER_ITEM_QUERY_PENDING",
    PlannerItemQueryRejected = "PLANNER_ITEM_QUERY_REJECTED",
    PlannerItemQueryFulfilled = "PLANNER_ITEM_QUERY_FULFILLED",
};

export type PlannerItemCreatedAction = Action<PlannerActionTypes.PlannerItemCreated> & {
    item: Spintr.PlannerItem;
};

export type PlannerItemDeletedAction = Action<PlannerActionTypes.PlannerItemDeleted> & {
    id: number;
};

export type PlannerItemUpdatedAction = Action<PlannerActionTypes.PlannerItemUpdated> & {
    item: Spintr.PlannerItem;
};

export type QueryPlannerItemsAction = Action<PlannerActionTypes.QueryPlannerItems> & {
    payload: Promise<Spintr.QueryEnvelope<Spintr.PlannerItem>>;
};

export type PlannerItemQueryPendingAction = Action<PlannerActionTypes.PlannerItemQueryPending>;

export type PlannerItemQueryRejectedAction = Action<PlannerActionTypes.PlannerItemQueryRejected> & {
    error: true;
    payload: AxiosError;
};

export type PlannerItemQueryFulfilledAction = Action<PlannerActionTypes.PlannerItemQueryFulfilled> & {
    payload: Spintr.QueryEnvelope<Spintr.PlannerItem>;
};

export type PlannerAction
    = PlannerItemCreatedAction
    | PlannerItemDeletedAction
    | PlannerItemUpdatedAction
    | QueryPlannerItemsAction
    | PlannerItemQueryPendingAction
    | PlannerItemQueryRejectedAction
    | PlannerItemQueryFulfilledAction;
