import React, { ReactElement, useCallback, useEffect, useMemo, useRef } from "react";
import { PlannerListProps } from "./PlannerList.types";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { IColumn } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { DataListFetchHandler } from "src/components/DataList";
import { queryPlannerItems } from "src/redux";
import { SpintrTypes } from "src/typings";
import moment from "moment";
import { localize } from "src/l10n";

const dateFormat = "YYYY-MM-DD";
const dateTimeFormat = "YYYY-MM-DD HH:mm";

function getColumns(): IColumn[] {
    return [{
        key: "name",
        name: "Name",
        fieldName: "name",
        minWidth: 100,
        isResizable: true,
    }, {
        key: "createdAt",
        name: "Created At",
        fieldName: "createdAt",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => moment(item.createdAt).format(dateTimeFormat),
    }, {
        key: "startsAt",
        name: "Starts At",
        fieldName: "startsAt",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => moment(item.startsAt).format(item.allDayEvent ? dateFormat : dateTimeFormat),
    }, {
        key: "endsAt",
        name: "Ends At",
        fieldName: "endsAt",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (item) => moment(item.endsAt).format(item.allDayEvent ? dateFormat : dateTimeFormat),
    }, {
        key: "contentStatus",
        name: "Status",
        fieldName: "contentStatus",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: function onRenderStatus(item: Spintr.PlannerItem) {
            switch (item.contentStatus) {
                case SpintrTypes.ContentStatus.Draft:
                    return localize("Utkast")
                case SpintrTypes.ContentStatus.Published:
                    return localize("Publicerad");
                case SpintrTypes.ContentStatus.Deleted:
                    return localize("Borttagen");
                default: return null;
            }
        }
    }];
}

function PlannerList(props: PlannerListProps): ReactElement {
    const { refetchRef, searchText } = props;
    const dispatch = useDispatch();
    const items = useSelector<Spintr.AppState, Spintr.PlannerItem[]>(
        (state) => state.planner.queryItemIds.map((id) => state.planner.itemsById[id]),
        (left, right) => {
            const length = Math.max(left.length, right.length);

            for (let i = 0; i < length; i++) {
                if (left[i] !== right[i]) {
                    return false;
                }
            }

            return true;
        },
    );

    const searchRef = useRef<SpintrList>(null);
    const columns = useMemo(getColumns, []);
    const refetch = useCallback(() => {
        if (!searchRef?.current) {
            return;
        }

        searchRef.current.reFetch();
    }, [searchRef]);

    const fetch = useCallback<DataListFetchHandler>(async (skip, take, orderBy, ascending, searchText) => {
        const dispatchResult = dispatch(queryPlannerItems({
            orderAscending: ascending,
            orderByColumn: orderBy,
            search: searchText,
            skip,
            take,
            status: SpintrTypes.ContentStatus.Published,
        }));

        // @ts-ignore Typings here are wrong as redux-think changes it
        const promise = dispatchResult as Promise<{ value: Spintr.QueryEnvelope<Spintr.PlannerItem> }>;
        const result = await promise;

        return { total: result.value.totalCount, };
    }, []);

    useEffect(() => {
        if (!refetchRef) {
            return;
        }

        refetchRef.current = refetch;

        return () => {
            if (refetchRef.current !== refetch) {
                return;
            }

            refetchRef.current = undefined;
        };
    }, [refetch, refetchRef]);

    return (
        <section className="PlannerList">
            <SpintrList
                columns={columns}
                data={{
                    data: items,
                }}
                disableCommandBar={true}
                disableSearch={true}
                disableSort={true}
                fetch={fetch}
                isDescending={false}
                orderByColumn="startsAt"
                searchText={searchText}
                ref={searchRef}
            />
        </section>
    );
}

export default PlannerList;
