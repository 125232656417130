import { lazy } from "react";
import { NotificationsView } from "src/notifications";
import { SearchResultView } from "src/search";
import { FilesRootView } from "../files";
import { GroupRootView, MyGroupsView } from "../groups/views";
import { GroupEditView } from "../groups/views";
import { GroupList } from "../groups/views";
import { AppRootView, MarketplaceRootView } from "../marketplace";
import { MediaView } from "../media/views";
import { PageFormView, PageView } from "../pages/views";
import { UserCatalogueViewWithSubmenu, UserEditView } from "../users/views";
import { BlogsRootView, BlogsPostView, BlogsView, BlogsPostEditView, BlogsEditView } from "../blogs";
import { SitemapView } from "src/sitemap";
import { NewsEditView, NewsCatalogView } from "src/news";
import NewsArticleView from "src/news/views/NewsArticleView";
import ProfileSettingsView from "src/profile/ProfileSettingsView";
import {
    WikiListView,
    WikiArticleListView,
    WikiArticleView,
    WikiEditView,
    WikiArticleEditView,
    WikiArticleSectionEditView,
} from "src/wikis/views";
import { GotoView } from "src/goto/views";
import ProfileView from "src/profile/ProfileView";
import TodoView from "src/todo/views/TodoView";
import TodoItemEditView from "src/todo/views/TodoItemEditView";
import TodoListEditView from "src/todo/views/TodoListEditView";
import TodoItemView from "src/todo/views/TodoItemView";
import QAView from "src/qa/QAView";
import QAEditView from "src/qa/QAEditView";
import QASettingsView from "src/qa/QASettingsView";
import ActivityLogView from "src/activitylog/ActivityLogView";
import SuppliersView from "src/suppliers/SuppliersView";
import NotesView from "src/notes/components/NotesView";
import {
    OrganisationListView,
    OrganisationUnitEditView,
    OrganisationInviteView,
    OrganisationUnitView,
    OrganisationAdminFormView,
} from "src/organisation/views";
import { OperatinginfoEditView, OperatinginfoListView, OperatingSupportView } from "src/operatinginfo/views";
import AbsenceView from "src/absence/AbsenceView";
import AbsenceEditView from "src/absence/AbsenceEditView";
import ResourceBookingView from "src/bookings/ResourceBookingView";
import ResourceEditView from "src/bookings/ResourceEditView";
import ResourceWeeklyView from "src/bookings/ResourceWeeklyView";
import ErrorPage from "src/ui/components/ErrorPage";
import LoaderPage from "src/ui/components/LoaderPage";
import PublishingView from "src/content-review/PublishingView";
import CalendarRootView from "src/calendar/components/CalendarRootView";
import { MessagesRootView } from "src/chat/views";
import SocialPostView from "src/social-feed/components/SocialPostView/SocialPostView";
import MyOfficeRedirectView from "src/organisation/views/MyOfficeRedirectView";
import MyUnitRedirectView from "src/organisation/views/MyUnitRedirectView";
import TagsView from "src/tags/TagsView";
import GroupUpgradeView from "src/groups/views/GroupUpgradeView";
import WebViewView from "src/webview/views/WebViewView";
import SuppliersCategoriesView from "src/suppliers/SuppliersCategoriesView";
import SuppliersContactPersonsView from "src/suppliers/SuppliersContactPersonsView";
import SuppliersFileCategoriesView from "src/suppliers/SuppliersFileCategoriesView";
import SuppliersProductCodesView from "src/suppliers/SuppliersProductCodesView";
import SuppliersCategoriesEditView from "src/suppliers/SuppliersCategoriesEditView";
import SuppliersFileCategoriesEditView from "src/suppliers/SuppliersFileCategoriesEditView";
import SuppliersEditView from "src/suppliers/SuppliersEditView";
import SuppliersSettingsView from "src/suppliers/SuppliersSettingsView";
import SupplierView from "src/suppliers/SupplierView";
import MenuPage from "src/menu/components/MenuPage";
import { Public360MainView } from "src/public360/views";
import TodoListByTypeView from "src/todo/views/TodoListByTypeView";
import MyPagesView from "src/pages/views/MyPagesView";
import GroupsStartView from "src/groups/views/GroupsStartView";
import ImprovementsView from "src/improvements/ImprovementsView";
import AppFeedbackView from "src/app-feedback/AppFeedbackView";
import SuggestionsView from "src/suggestions/SuggestionsView";
import SuppliersRolesView from "src/suppliers/SuppliersRolesView";
import SuppliersRolesEditView from "src/suppliers/SuppliersRolesEditView";
import SuppliersMessagesView from "src/suppliers/SuppliersMessagesView";
import SuppliersFilesView from "src/suppliers/SuppliersFilesView";
import SuppliersGroupsView from "src/suppliers/SuppliersGroupsView";
import SuppliersGroupsEditView from "src/suppliers/SuppliersGroupsEditView";
import { StartPageEditView } from "./views";
import PayrollView from "src/payroll/PayrollView";
import AcademyView from "src/academy/courses/AcademyView/AcademyView";
import AcademyCourseView from "src/academy/course/AcademyCourseView/AcademyCourseView";
import { routes as productRoutes } from "src/products";
import wikiRoutes from "src/wikis/routes";
import { plannerRoutes } from "src/planner";

const AdminRootView = lazy(() => import("src/admin/views/AdminRootView"));

const getRoutes = (): Spintr.ISpintrUiRoute[] => {
    return [
        { path: "/404", component: ErrorPage },
        { path: "/loader", component: LoaderPage },
        ...productRoutes,
        ...plannerRoutes,
        { path: "/admin", component: AdminRootView },
        { path: "/absence", exact: true, component: AbsenceView },
        { path: "/absence/create", component: AbsenceEditView },
        { path: "/absence/:id/edit", component: AbsenceEditView },
        { path: "/app/:idOrSlug/", exact: false, component: AppRootView },
        { path: "/booking", exact: true, component: ResourceBookingView },
        { path: "/booking/create", component: ResourceEditView },
        { path: "/booking/:categoryId/:id/edit", component: ResourceEditView },
        { path: "/booking/:categoryId/:id/:year/:week", component: ResourceWeeklyView },
        { path: "/booking/:categoryId/:id", component: ResourceWeeklyView },
        { path: "/calendar", exact: true, component: CalendarRootView },
        { path: "/calendar/:calendarId/:view", exact: true, component: CalendarRootView },
        { path: "/calendar/:eventId", exact: true, component: CalendarRootView },
        { path: "/sitemap", component: SitemapView },
        { path: "/menu-page", component: MenuPage },
        { path: "/tags", component: TagsView },
        { path: "/activitylog", exact: true, component: ActivityLogView },
        { path: "/suppliers", exact: true, component: SuppliersView },
        { path: "/suppliers/settings", exact: true, component: SuppliersSettingsView },
        { path: "/suppliers/categories", exact: true, component: SuppliersCategoriesView },
        { path: "/suppliers/roles", exact: true, component: SuppliersRolesView },
        { path: "/suppliers/contactpersons", exact: true, component: SuppliersContactPersonsView },
        { path: "/suppliers/filecategories", exact: true, component: SuppliersFileCategoriesView },
        { path: "/suppliers/product-codes", exact: true, component: SuppliersProductCodesView },
        { path: "/suppliers/create", component: SuppliersEditView },
        { path: "/suppliers/messages", exact: true, component: SuppliersMessagesView },
        { path: "/suppliers/files", exact: true, component: SuppliersFilesView },
        { path: "/suppliers/groups", exact: true, component: SuppliersGroupsView },
        { path: "/suppliers/:id", exact: true, component: SupplierView },
        { path: "/suppliers/:id/edit", component: SuppliersEditView },
        { path: "/suppliers/categories/:id/edit", component: SuppliersCategoriesEditView },
        { path: "/suppliers/filecategories/:id/edit", component: SuppliersFileCategoriesEditView },
        { path: "/suppliers/roles/:id/edit", component: SuppliersRolesEditView },
        { path: "/suppliers/groups/:id/edit", component: SuppliersGroupsEditView },
        { path: "/suppliers/categories/create", component: SuppliersCategoriesEditView },
        { path: "/suppliers/filecategories/create", component: SuppliersFileCategoriesEditView },
        { path: "/suppliers/roles/create", component: SuppliersRolesEditView },
        { path: "/suppliers/groups/create", component: SuppliersGroupsEditView },
        { path: "/notes/:noteId", component: NotesView },
        { path: "/notes", component: NotesView },
        { path: "/qa/create", component: QAEditView },
        { path: "/qa/settings", component: QASettingsView },
        { path: "/qa/:id", exact: true, component: QAView },
        { path: "/qa/:id/edit", exact: true, component: QAEditView },
        { path: "/qa", exact: true, component: QAView },
        { path: "/todo/create", component: TodoListEditView },
        { path: "/todo/list/:listId/:id/edit", component: TodoItemEditView },
        { path: "/todo/list/:listId/create", component: TodoItemEditView },
        { path: "/todo/list/:listId/edit", component: TodoListEditView },
        { path: "/todo/list/:listId/:id", exact: true, component: TodoItemView },
        { path: "/todo/list/:listId", exact: true, component: TodoView },
        { path: "/todo/type/:typeId", exact: true, component: TodoListByTypeView },
        { path: "/todo", component: TodoView },
        { path: "/profile/:id", component: ProfileView },
        { path: "/profile-settings", component: ProfileSettingsView },
        { path: "/messages", exact: false, component: MessagesRootView },
        { path: "/news/my/:category", exact: true, component: NewsCatalogView },
        { path: "/news/admin/:category", exact: true, component: NewsCatalogView },
        { path: "/news", exact: true, component: NewsCatalogView },
        { path: "/news/top", exact: true, component: NewsCatalogView },
        { path: "/news/create", exact: true, component: NewsEditView },
        { path: "/news/:slug+/edit", exact: true, component: NewsEditView },
        { path: "/news/edit/:id/review/:versionId", exact: true, component: NewsEditView },
        { path: "/news/edit/:id", exact: true, component: NewsEditView },
        { path: "/news/:slug+", exact: true, component: NewsArticleView },
        { path: "/notifications", exact: true, component: NotificationsView },
        { path: "/blogs/my", exact: true, component: BlogsRootView },
        { path: "/blogs/list", exact: true, component: BlogsRootView },
        { path: "/blogs/popular", exact: true, component: BlogsRootView },
        { path: "/blogs/history", exact: true, component: BlogsRootView },
        { path: "/blogs/history/:date", exact: true, component: BlogsRootView },
        { path: "/blogs/tags/:tag", exact: true, component: BlogsRootView },
        { path: "/blogs/create", exact: true, component: BlogsEditView },
        { path: "/blogs/:blogSlug/create", exact: true, component: BlogsPostEditView },
        { path: "/blogs/:blogSlug/edit", exact: true, component: BlogsEditView },
        { path: "/blogs/:blogSlug/:postSlug/edit", exact: true, component: BlogsPostEditView },
        { path: "/blogs/:blogSlug/archive", component: BlogsRootView },
        { path: "/blogs/:blogSlug/drafts", component: BlogsRootView },
        { path: "/blogs/:blogSlug/published", component: BlogsRootView },
        { path: "/blogs/:blogSlug/planned", component: BlogsRootView },
        { path: "/blogs/:blogSlug/expired", component: BlogsRootView },
        { path: "/blogs/:blogSlug/deleted", component: BlogsRootView },
        { path: "/blogs/:blogSlug/:postSlug", exact: true, component: BlogsPostView },
        { path: "/blogs/:blogSlug", exact: true, component: BlogsView },
        { path: "/blogs", exact: true, component: BlogsRootView },
        { path: "/files", exact: true, component: FilesRootView },
        { path: "/files/:source/:id", exact: true, component: FilesRootView },
        { path: "/files/:source/:id/version", exact: true, component: FilesRootView },
        { path: "/files/:source/:id/edit", exact: true, component: FilesRootView },
        { path: "/goto/:uberId", exact: true, component: GotoView },
        { path: "/goto/:typeId/:uberId", exact: true, component: GotoView },
        { path: "/my-groups", exact: true, component: MyGroupsView },
        { path: "/groups", exact: true, component: GroupsStartView },
        { path: "/all-groups", exact: true, component: GroupList },
        { path: "/groups/create", exact: true, component: GroupEditView },
        { path: "/groups/:id/upgrade", exact: true, component: GroupRootView },
        { path: "/groups/:id/edit", exact: true, component: GroupEditView },
        { path: "/groups/:id", component: GroupRootView },
        { path: "/groups/:id/support", component: GroupRootView },
        { path: "/groups/:id/planning", component: GroupRootView },
        { path: "/groups/:id/planning/:id", component: GroupRootView },
        { path: "/groups/:id/notes", component: GroupRootView },
        { path: "/groups/:id/notes/:noteId", component: GroupRootView },
        { path: "/groups/:id/files", component: GroupRootView },
        { path: "/groups/:id/calendar", component: GroupRootView },
        { path: "/groups/:id/members", component: GroupRootView },
        { path: "/groups/:id/start", component: GroupRootView },
        { path: "/groups/:id/todo", component: GroupRootView },
        { path: "/groups/:id/classic-notes", component: GroupRootView },
        { path: "/groups/:id/chat", component: GroupRootView },
        { path: "/groups/:id/feed/:statusId", component: GroupRootView },
        { path: "/groups/:id/feed", component: GroupRootView },
        { path: "/groups/:id/:path", component: GroupRootView },
        { path: "/people", exact: true, component: UserCatalogueViewWithSubmenu },
        { path: "/people/create", component: UserEditView },
        { path: "/people/:id/edit", component: UserEditView },
        { path: "/public360", component: Public360MainView },
        { path: "/marketplace", component: MarketplaceRootView },
        { path: "/media", exact: true, component: MediaView },
        { path: "/media/folder/:folderId", exact: true, component: MediaView },
        { path: "/media/image/:imageId", exact: true, component: MediaView },
        { path: "/operatinginfo/create", exact: true, component: OperatinginfoEditView },
        { path: "/operatinginfo/support", component: OperatingSupportView },
        { path: "/operatinginfo/:id/edit", component: OperatinginfoEditView },
        { path: "/operatinginfo", component: OperatinginfoListView },
        { path: "/organisation", exact: true, component: OrganisationListView },
        { path: "/organisation/c/:companyId", exact: true, component: OrganisationListView },
        { path: "/organisation/invite", exact: true, component: OrganisationInviteView },
        { path: "/organisation/:firstId/:secondId?/:thirdId?/edit", exact: true, component: OrganisationAdminFormView },
        { path: "/organisation/:firstId/:secondId?/create", exact: true, component: OrganisationAdminFormView },
        { path: "/organisation/:unitId", exact: true, component: OrganisationListView },
        { path: "/organisation/v/:ignore?/:ignore?/:unitId/settings", component: OrganisationUnitView },
        { path: "/organisation/v/:unitId", component: OrganisationUnitView },
        { path: "/my-office", component: MyOfficeRedirectView },
        { path: "/my-unit", component: MyUnitRedirectView },
        { path: "/search", exact: false, component: SearchResultView },
        ...wikiRoutes,
        { path: "/pages/:id/edit", component: PageFormView },
        { path: "/pages/:id/review/:versionId", component: PageFormView },
        { path: "/pages/create", component: PageFormView },
        { path: "/publishing", exact: true, component: PublishingView },
        { path: "/posts/:statusId", component: SocialPostView },
        { path: "/verksamhetshandboken", component: Public360MainView },
        { path: "/webview/:url+", component: WebViewView },
        { path: "/webview//:url+", component: WebViewView },
        { path: "/my-pages", component: MyPagesView },
        { path: "/improvements", component: ImprovementsView },
        { path: "/appfeedback", component: AppFeedbackView},
        { path: "/suggestions", component: SuggestionsView },
        { path: "/edit-startpage/:id", component: StartPageEditView },
        { path: "/edit-startpage", exact: true, component: StartPageEditView },
        { path: "/payroll", component: PayrollView},
        { path: "/academy", exact: true, component: AcademyView},
        { path: "/academy/:id", component: AcademyCourseView},
        { path: "/:path", component: PageView },
    ];
}

export default getRoutes;
