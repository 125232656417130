import React, { ReactElement } from "react";
import { Heading, Planner } from "src/components";
import { localize } from "src/l10n";

function PlannerItemsView(): ReactElement {
    return (
        <section id="PlannerItemsView">

            <header className="PlannerItemsView-heading">
                <Heading color="contentDark" lineHeight="normal" weight="medium">
                    {localize("PLANNER")}
                </Heading>
            </header>
            
            <Planner initialTimelineMode={"WEEKS"} />
        </section>
    );
}

export default PlannerItemsView;
