import { SpintrTypes } from "src/typings";
import { ColumnDuration, TimelineMode } from "./types";
import { localize } from "src/l10n";

export function getWeekStart(date: Date, weekStartsOn?: number | undefined): Date {
    const weekStart = weekStartsOn ?? 1;

    const day = date.getDay();
    const diff = (day < weekStart ? 7 : 0) + day - weekStart;

    const retVal = new Date(date.getTime());
    retVal.setDate(date.getDate() - diff);

    return retVal;
}

function getDurationStartDate(now: Date, mode: TimelineMode): Date {
    const start = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());

    switch (mode) {
        case "DAYS":
            start.setHours(0, 0, 0, 0);
            return start;

        default:
        case "WEEKS":
            return getWeekStart(start);          
            
        case "MONTHS":
            start.setDate(1);
            start.setHours(0, 0, 0, 0);
            return start;

        case "QUARTERS":
            const startMonth = start.getMonth() - (start.getMonth() % 3);
            start.setMonth(startMonth, 1);
            start.setHours(0, 0, 0, 0);
            return start;
    }
}

function getDurationEndDate(start: Date, mode: TimelineMode): Date {
    const end = new Date(start.getTime());
    end.setFullYear(start.getFullYear() + 3);

    switch (mode) {
        case "DAYS":
            end.setHours(23, 59, 59, 999);
            return end;

        default:
        case "WEEKS":
            const diff = end.getDay() === 0 ? 0 : 7 - end.getDay();
            end.setDate(end.getDate() + diff);
            end.setHours(23, 59, 59, 999);
            return end;

        case "MONTHS":
            end.setMonth(end.getMonth() + 1, 0);
            end.setHours(23, 59, 59, 999);
            return end;

        case "QUARTERS":
            const endMonth = end.getMonth() + 2 - (end.getMonth() % 3);
            end.setMonth(endMonth + 1, 0);
            end.setHours(23, 59, 59, 999);
            return end;
    }
}

export function getColumnDurations(start: Date, end: Date, mode: TimelineMode): ColumnDuration[] {
    const columns: ColumnDuration[] = [];

    let current = new Date(start.getTime());
    while (current.getTime() < end.getTime()) {
        let currentEnd: Date;

        switch (mode) {
            case "DAYS":
                currentEnd = new Date(current.getTime());
                currentEnd.setHours(23, 59, 59, 999);
                break;

            default:
            case "WEEKS":
                currentEnd = new Date(current.getTime());
                currentEnd.setDate(current.getDate() + 6);
                break;
            
            case "MONTHS":
                currentEnd = new Date(current.getTime());
                currentEnd.setMonth(current.getMonth() + 1, 0);
                break;

            case "QUARTERS":
                currentEnd = new Date(current.getTime());
                currentEnd.setMonth((current.getMonth() + 2 - (current.getMonth() % 3)) + 1, 0);
                break;
        }

        currentEnd.setHours(23, 59, 59, 999);

        if (currentEnd.getTime() > end.getTime()) {
            currentEnd = new Date(end.getTime());
        }

        columns.push({
            startMilliseconds: current.getTime(),
            totalMilliseconds: currentEnd.getTime() - current.getTime(),
            endMilliseconds: currentEnd.getTime(),
        });

        switch (mode) {
            case "DAYS":
                current.setDate(current.getDate() + 1);
                break;

            default:
            case "WEEKS":
                current.setDate(current.getDate() + 7);
                break;
            
            case "MONTHS":
                current.setMonth(current.getMonth() + 1);
                break;

            case "QUARTERS":
                const nextMonth = current.getMonth() + 3
                current.setMonth(nextMonth - (nextMonth % 3), 1);
                break;
        }
    }

    return columns;
}

export function generateColumnDurations(now: Date, mode: TimelineMode): ColumnDuration[] {
    const start = getDurationStartDate(now, mode);

    return getColumnDurations(
        start,
        getDurationEndDate(start, mode),
        mode
    );
}

export function getTimelineDuration(columns: ColumnDuration[]): ColumnDuration {
    return {
        startMilliseconds: columns[0].startMilliseconds,
        endMilliseconds: columns[columns.length - 1].endMilliseconds,
        totalMilliseconds: columns[columns.length - 1].endMilliseconds - columns[0].startMilliseconds,
    };
}

export function getPlannerItemTypeText(type: Spintr.PlannerItemType): string {
    switch (type) {
        default:
        case SpintrTypes.PlannerItemType.General:
            return localize("PLANNER_TYPE_GENERAL");

        case SpintrTypes.PlannerItemType.Campaign:
            return localize("PLANNER_TYPE_CAMPAIGN");

        case SpintrTypes.PlannerItemType.Course:
            return localize("PLANNER_TYPE_COURSE");

        case SpintrTypes.PlannerItemType.Delivery:
            return localize("PLANNER_TYPE_DELIVERY");

        case SpintrTypes.PlannerItemType.Event:
            return localize("PLANNER_TYPE_EVENT");

        case SpintrTypes.PlannerItemType.Inventory:
            return localize("PLANNER_TYPE_INVENTORY");

        case SpintrTypes.PlannerItemType.Maintenance:
            return localize("PLANNER_TYPE_MAINTENANCE");

        case SpintrTypes.PlannerItemType.Release:
            return localize("PLANNER_TYPE_RELEASE");
    }
}

export function getPlannerResourceTypeText(type: Spintr.PlannerItemResourceType): string | undefined {
    switch (type) {
        case SpintrTypes.PlannerItemResourceType.Equipment:
            return localize("PLANNER_RESOURCE_EQUIPMENT");

        case SpintrTypes.PlannerItemResourceType.Material:
            return localize("PLANNER_RESOURCE_MATERIAL");

        case SpintrTypes.PlannerItemResourceType.Personnel:
            return localize("PLANNER_RESOURCE_PERSONNEL");
    }

    return undefined;
}
