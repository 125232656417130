import React, { useCallback, useEffect, useRef, useState } from 'react';
import "./IsTyping.scss";
import { connect } from 'react-redux';
import { IApplicationState } from 'src/spintr/reducer';
import moment from 'moment';
import { AssistantTypingLoader } from 'src/assistant/components/AssistantTypingLoader';
import { SpintrUser } from 'src/ui';

interface IProps {
    conversationId: number;
    conversation?: Spintr.IConversation;
    isTyping?: { [userId: number]: Date };
    onVisible: () => void;
}

interface IState {
    participant?: Spintr.IConversationParticipant;
    index: number;
}

const IsTyping = (props: IProps) => {
    const ref = useRef<HTMLDivElement>();

    const getTimeLimit = () => {
        return moment().subtract(5, "seconds").toDate();
    }

    const getParticipant = useCallback((timeLimit: Date) => {
        if (!props.isTyping) {
            return null;
        }

        const items = Object.entries(props.isTyping).map(([key, value]) => ({
            userId: key,
            date: value
        })).filter(item => item.date > timeLimit).sort((a, b) => a.date < b.date ? -1 : 1);
    
        if (items.length === 0) {
            return null;
        }
    
        const item = items[0];

        const foundParticipant = props.conversation.participants.find(x => x.id.toString() === item.userId);

        return foundParticipant;
    }, [props.isTyping]);

    const [state, setState] = useState<IState>({
        participant: getParticipant(getTimeLimit()),
        index: 0
    });

    useEffect(() => {
        const participant = getParticipant(getTimeLimit());

        if (participant?.id !== state.participant?.id) {
            setState((s) => ({
                ...s,
                participant
            }));
        }

        if (participant) {
            props.onVisible();
        }
    }, [props.isTyping, state.index]);

    useEffect(() => {
        const i = setInterval(() => {
            setState((s) => ({
                ...s,
                index: s.index + 1
            }));
        }, 5000);

        return () => clearInterval(i);
    }, []);

    if (!state.participant) {
        return null;
    }

    return (
        <div ref={ref} className="IsTyping">
            <SpintrUser
                name={state.participant.name}
                imageUrl={state.participant.imageUrl}
                personalName={true}
                hideText
            />
            <AssistantTypingLoader />
        </div>
    )
}

export default connect(
    (state: IApplicationState, props: IProps): IProps => ({
        ...props,
        conversation: state.chat.conversations.items.find(x => x.id === props.conversationId),
        isTyping: state.chat.conversations.isTyping[props.conversationId]
    })
)(IsTyping);
