import { TextField } from "@fluentui/react";
import React, { FormEventHandler, Fragment, ReactElement, useCallback } from "react";
import { localize } from "src/l10n";
import { FormControl } from "src/ui/components/Forms";
import { PlannerItemFormProps } from "./PlannerItemForm.types";
import { PlannerModel } from "../types";
import { SpintrTypes } from "src/typings";
import { PlannerFilePicker } from "../PlannerFilePicker";

function PlannerDeliveryForm(props: PlannerItemFormProps<Spintr.PlannerDelivery>): ReactElement {
    const { errors, model, onModelUpdate } = props

    const onTextFieldChanged = useCallback<FormEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (event) => {
            if (!event?.target) {
                return;
            }

            const { name, value } = (event.target as HTMLInputElement | HTMLTextAreaElement);

            onModelUpdate((prevModel) => Object.assign(
                { ...prevModel },
                { [name]: value },
            ) as PlannerModel);
        },
        [onModelUpdate],
    );

    if (model.itemType !== SpintrTypes.PlannerItemType.Delivery) {
        return null;
    }

    return (
        <Fragment>
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_DELIVERY_SUPPLIER")}
                    name="supplier"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.supplier}
                    value={model.supplier || ""}
                />
            </FormControl>

            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_DELIVERY_PLAN")}
                    name="deliveryPlan"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.deliveryPlan}
                    value={model.deliveryPlan || ""}
                />
            </FormControl>

            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_DELIVERY_GOODS")}
                    name="goodsDescription"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.goodsDescription}
                    value={model.goodsDescription || ""}
                />
            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_DELIVERY_PLAN")}
                    name="deliveryPlan"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.deliveryPlan}
                    value={model.deliveryPlan || ""}
                />

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={["pdf", "doc", "docx", "odt"]}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerDeliveryFileType.DeliveryPlan}
                    onModelUpdate={onModelUpdate}
                    simple={true} />

            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_DELIVERY_PROOF")}
                    name="proofOfDelivery"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.proofOfDelivery}
                    value={model.proofOfDelivery || ""}
                />

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={["pdf", "doc", "docx", "odt"]}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerDeliveryFileType.ProofOfDelivery}
                    onModelUpdate={onModelUpdate}
                    simple={true} />

            </FormControl>

            {/* TODO: Maybe add a link to Supplier? */}
        </Fragment>
    );
}

export default PlannerDeliveryForm;
