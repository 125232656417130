import React, { ReactElement, useCallback, useMemo } from "react";
import { PlannerCommandBarProps } from "./PlannerCommandBar.types";
import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import { localize } from "src/l10n";
import { UnstyledButton } from "src/ui";
import classNames from "classnames";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

function PlannerCommandBar(props: PlannerCommandBarProps): ReactElement {
    const {
        onCreateClick,
        onTimelineModeChange,
        onSearchChange,
        onViewModeChange,
        searchText,
        timelineMode,
        viewMode,
    } = props;

    const onSearchChanged = useCallback(
        (_: unknown, newValue?: string) => onSearchChange?.(newValue ?? ""),
        [onSearchChange],
    );

    const mainItems = useMemo(() => {
        const items: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: function renderTimelineSearch(): ReactElement {
                return (
                    <SpintrSearch
                        classes="searchBox CommandBar-search"
                        onChange={onSearchChanged}
                        value={searchText} />
                );
            },
        });

        if (onViewModeChange) {
            items.push({
                key: "view-mode-switch",
                onRender: function renderViewModeSwitch(): ReactElement {
                    return (
                        <div className="viewMode-switch">
                            <UnstyledButton
                                className={classNames("switch-button", {
                                    active: viewMode === "LIST",
                                })}
                                onClick={() => onViewModeChange("LIST")}
                            >
                                <Visage2Icon
                                    color={viewMode === "LIST" ? "visageMidBlue" : "primaryContent"}
                                    icon="menu-1"
                                    size="small" />
                            </UnstyledButton>
                            <UnstyledButton
                                className={classNames("switch-button", {
                                    active: viewMode === "TIMELINE",
                                })}
                                onClick={() => onViewModeChange("TIMELINE")}
                            >
                                <Visage2Icon
                                    color={viewMode === "TIMELINE" ? "visageMidBlue" : "primaryContent"}
                                    icon="calendar-2"
                                    size="small" />
                            </UnstyledButton>
                        </div>
                    );
                },
            });
        }

        if (viewMode === "TIMELINE") {
            let textKey: string;
            switch (timelineMode) {
                case "DAYS":
                    textKey = "TIMELINE_DAYS";
                    break;

                default:
                case "WEEKS":
                    textKey = "TIMELINE_WEEKS";
                    break;

                case "MONTHS":
                    textKey = "TIMELINE_MONTHS";
                    break;

                case "QUARTERS":
                    textKey = "TIMELINE_QUARTERS";
                    break;
            }

            items.push({
                key: "timeline-mode",
                className: "timelineModeButton commandBarButton",
                text: localize(textKey),
                subMenuProps: {
                    items: [{
                        key: "timeline-days",
                        onClick: () => onTimelineModeChange?.("DAYS"),
                        text: localize("TIMELINE_DAYS"),
                    }, {
                        key: "timeline-weeks",
                        onClick: () => onTimelineModeChange?.("WEEKS"),
                        text: localize("TIMELINE_WEEKS"),
                    }, {
                        key: "timeline-months",
                        onClick: () => onTimelineModeChange?.("MONTHS"),
                        text: localize("TIMELINE_MONTHS")
                    }, {
                        key: "timeline-quarters",
                        onClick: () => onTimelineModeChange?.("QUARTERS"),
                        text: localize("TIMELINE_QUARTERS")
                    }]
                },
            });
        }

        items.push({
            key: "create-button",
            className: "primaryBGColor primaryTextContrast commandBarAddButton commandBarButton",
            iconProps: { iconName: "Add" },
            text: localize("SkapaNy"),
            onClick: onCreateClick,
        });

        return items;
    }, [onSearchChanged, onTimelineModeChange, onViewModeChange, searchText, timelineMode, viewMode]);

    const farItems = useMemo<ICommandBarItemProps[]>(() => [
            
    ], []);

    return (
        <div className="PlannerCommandBar">

            <CommandBar
                className="spintr-list-command-bar PlannerCommandBar-bar CommandBar-GrowSearch"
                farItems={farItems}
                items={mainItems} />

        </div>
    );
}

export default PlannerCommandBar;
