import { PlannerAction, PlannerActionTypes } from "../types";

export type PlannerState = {
    itemsById:          { [id: number]: Spintr.PlannerItem };
    allItemIds:         number[];
    
    queryItemIds:       number[];
    queryPending:       boolean;
    queryResultCount:   number;
};

const initialState: PlannerState = {
    itemsById:          {},
    allItemIds:         [],

    queryItemIds:       [],
    queryPending:       false,
    queryResultCount:   0,
};

export function plannerReducer(state: PlannerState = initialState, action: PlannerAction) {
    switch (action.type) {
        case PlannerActionTypes.PlannerItemCreated:
            return {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [action.item.id]: action.item,
                },
                allItemIds: state.allItemIds.every(id => id !== action.item.id)
                    ? [...state.allItemIds, action.item.id]
                    : state.allItemIds,
            };

        case PlannerActionTypes.PlannerItemDeleted:
            return {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [action.id]: undefined,
                },
                allItemIds: state.allItemIds.filter(id => id !== action.id),
                filteredItemIds: state.queryItemIds.filter(id => id !== action.id),
            };

        case PlannerActionTypes.PlannerItemUpdated:
            return {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [action.item.id]: action.item,
                },
                allItemIds: state.allItemIds.every(id => id !== action.item.id)
                    ? [...state.allItemIds, action.item.id]
                    : state.allItemIds,
            };

        case PlannerActionTypes.PlannerItemQueryPending:
            return {
                ...state,
                queryPending: true,
            };

        case PlannerActionTypes.PlannerItemQueryRejected:
            return {
                ...state,
                queryPending: false,
            };

        case PlannerActionTypes.PlannerItemQueryFulfilled:
            const updatedItems = action.payload.items.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, { ...state.itemsById});

            return {
                ...state,
                itemsById: updatedItems,
                allItemIds: Object.keys(updatedItems).map((id) => parseInt(id, 10)),
                queryItemIds: action.payload.items.map((item) => item.id),
                queryPending: false,
                queryResultCount: action.payload.totalCount,
            }

        default:
            return state;
    }
}
