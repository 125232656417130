const vars = {
    baseUnit: 3
}

export const getHexFromSpintrColor = (color?: spintrColors) => {
    switch (color) {
        case "black":
            return "#000000";
        case "dark-grey":
            return "#091B3D";
        case "dusk-grey":
            return "#d5d5d5";
        case "grey":
            return "#909090";
        case "light-blue":
            return "#0059B8";
        case "light-grey":
            return "#F8F8FF";
        case "mid-grey":
            return "#566176";
        case "red":
            return "#ff1429";
        case "white":
            return "#FFFFFF";
        case "green":
            return "#1F7F35";
        case "smoke":
            return "#EAEAEA";
        case "salmon":
            return "#E45864";
        case "yellow":
            return "#ADA000";
        case "visageGray3":
            return "#6D7588";
        case "neutralBlue":
            return "#2772F0";
        case "borderColor":
            return "#EDEDED";
        case "disabled":
            return "#a19f9d"
        case "visage2LightGray":
            return "#F4F4F5";
        case "visageMidBlueBackground":
            return "#F1F2FC";
        case "dark-grey":
            return "#22234A";
        case "visage2LightGray2":
            return "#ECF0F3";
        case "visageMidBlue":
            return "#787CDD";

        default:
            return "#091B3D";
    }
};

export const getNumberFromIconSize = (iconSize: iconSize) => {
    switch (iconSize) {
        case "extra-small":
            return 12;
        case "small":
            return 16;
        case "medium":
            return 20;
        case "large":
            return 24;
        case "extra-large":
            return 28;
        case "huge":
            return 40;
        default:
            return 20;
    }
}

export const circleXSmall = 24;
export const circleSmall = 28;
export const circleMediumSmall = 32;
export const circleMedium = 36;
export const circleLarge = 42;
export const circleXLarge = 54;

export const listActionMenuWidth = 24;
export const headerHeight = 90;
export const formFooterBarHeight = 76;

export const getSpacing = (size: number) => {
    return vars.baseUnit * size;
};

export const getSpacingStr = (size: number) => {
    return getSpacing(size) + 'px';
};
