import { TextField } from "@fluentui/react";
import React, { FormEventHandler, Fragment, ReactElement, useCallback } from "react";
import { localize } from "src/l10n";
import { FormControl } from "src/ui/components/Forms";
import { PlannerItemFormProps } from "./PlannerItemForm.types";
import { PlannerModel } from "../types";
import { SpintrTypes } from "src/typings";
import { PlannerFilePicker } from "../PlannerFilePicker";

function PlannerReleaseForm(props: PlannerItemFormProps<Spintr.PlannerRelease>): ReactElement {
    const { errors, model, onModelUpdate } = props

    const onTextFieldChanged = useCallback<FormEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (event) => {
            if (!event?.target) {
                return;
            }

            const { name, value } = (event.target as HTMLInputElement | HTMLTextAreaElement);

            onModelUpdate((prevModel) => Object.assign(
                { ...prevModel },
                { [name]: value },
            ) as PlannerModel);
        },
        [onModelUpdate],
    );

    if (model.itemType !== SpintrTypes.PlannerItemType.Release) {
        return null;
    }

    return (
        <Fragment>
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_RELEASE_NAME")}
                    name="productName"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.productName}
                    value={model.productName || ""}
                />
            </FormControl>
            
            <FormControl label={localize("PLANNER_RELEASE_PLAN")}>

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={["pdf", "doc", "docx", "odt"]}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerReleaseFileType.RolloutPlan}
                    onModelUpdate={onModelUpdate}
                    simple={false} />

            </FormControl>

            <FormControl>

                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_RELEASE_SALES_TARGET")}
                    multiline={true}
                    name="salesTarget"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.salesTarget}
                    value={model.salesTarget || ""} />

            </FormControl>
            
            <FormControl label={localize("PLANNER_RELEASE_MARKETING")}>

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={["pdf", "doc", "docx", "odt"]}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerReleaseFileType.MarketingMaterial}
                    onModelUpdate={onModelUpdate}
                    simple={false} />

            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_RELEASE_STRATEGY")}
                    multiline={true}
                    name="launchStrategy"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.launchStrategy}
                    value={model.launchStrategy || ""}
                />

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={["pdf", "doc", "docx", "odt"]}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerReleaseFileType.LaunchStrategy}
                    onModelUpdate={onModelUpdate}
                    simple={true} />

            </FormControl>

        </Fragment>
    );
}

export default PlannerReleaseForm;
