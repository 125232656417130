import { TextField } from "@fluentui/react";
import React, { FormEventHandler, Fragment, ReactElement, useCallback } from "react";
import { localize } from "src/l10n";
import { FormControl } from "src/ui/components/Forms";
import { PlannerItemFormProps } from "./PlannerItemForm.types";
import { PlannerModel } from "../types";
import { SpintrTypes } from "src/typings";
import { PlannerFilePicker } from "../PlannerFilePicker";

function PlannerMaintenanceForm(props: PlannerItemFormProps<Spintr.PlannerMaintenance>): ReactElement {
    const { errors, model, onModelUpdate } = props

    const onTextFieldChanged = useCallback<FormEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (event) => {
            if (!event?.target) {
                return;
            }

            const { name, value } = (event.target as HTMLInputElement | HTMLTextAreaElement);

            onModelUpdate((prevModel) => Object.assign(
                { ...prevModel },
                { [name]: value },
            ) as PlannerModel);
        },
        [onModelUpdate],
    );

    if (model.itemType !== SpintrTypes.PlannerItemType.Maintenance) {
        return null;
    }

    return (
        <Fragment>
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_MAINTENANCE_TYPE")}
                    name="maintenanceType"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.maintenanceType}
                    value={model.maintenanceType || ""}
                />
            </FormControl>

            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_MAINTENANCE_EQUIPMENT")}
                    name="effectedEquipment"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.effectedEquipment}
                    value={model.effectedEquipment || ""}
                />
            </FormControl>

            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_MAINTENANCE_DOWNTIME")}
                    name="expectedDowntime"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.expectedDowntime}
                    value={model.expectedDowntime || ""}
                />
            </FormControl>
            
            <FormControl label={localize("PLANNER_MAINTENANCE_PLAN")}>

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={["pdf", "doc", "docx", "odt"]}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerMaintenanceFileType.MaintenancePlan}
                    onModelUpdate={onModelUpdate}
                    simple={false} />

            </FormControl>
            
            <FormControl>
                <TextField
                    aria-required={false}
                    className="textField"
                    label={localize("PLANNER_MAINTENANCE_REPORT")}
                    name="maintenanceReport"
                    onChange={onTextFieldChanged}
                    required={false}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={errors.maintenanceReport}
                    value={model.maintenanceReport || ""} />

                <PlannerFilePicker
                    files={model.files}
                    fileTypes={["pdf", "doc", "docx", "odt"]}
                    multiple={true}
                    itemType={model.itemType}
                    fieldType={SpintrTypes.PlannerMaintenanceFileType.MaintenanceReport}
                    onModelUpdate={onModelUpdate}
                    simple={true} />

            </FormControl>

        </Fragment>
    );
}

export default PlannerMaintenanceForm;
