import React, { CSSProperties, ReactElement, useMemo } from "react";
import { CaptionBody } from "src/components";
import { localize } from "src/l10n";
import { TimelineHeaderProps } from "./TimelineHeader.types";
import { TimelineHeaderCell } from "../TimelineHeaderCell";

function TimelineHeader(props: TimelineHeaderProps): ReactElement {
    const { columnDurations, timelineMode, timelineSize, todayMs } = props;

    const dateContainerStyle = useMemo<CSSProperties>(
        () => ({
            width: `${timelineSize}px`,
        }),
        [timelineSize],
    );

    const todayCoordinates = useMemo<[number, number]>(() => {
        const columnIndex = columnDurations.reduce((acc, columnDuration, index) => {
            if (acc > -1) {
                return acc;
            }

            if (todayMs < columnDuration.startMilliseconds || columnDuration.endMilliseconds < todayMs) {
                return -1;
            }

            return index;
        }, -1);

        return [columnIndex, todayMs];
    }, [columnDurations, todayMs]);
    console.log(todayCoordinates);

    const cellWidth = timelineMode === "DAYS" ? 128 : 255;

    return (
        <header className="TimelineHeader" role="rowgroup">
            <div className="TimelineHeader-row" role="row">
                <div className="TimelineHeader-cell" role="rowheader">
                    <CaptionBody
                        className="TimelineHeader-axis-label"
                        color="contentLight"
                    >
                        {localize("Kategorier")}
                    </CaptionBody>
                </div>
                <div
                    className="TimelineHeader-date-labels-container"
                    style={dateContainerStyle}
                >
                    <div className="TimelineHeader-date-labels">
                        {columnDurations.map((_, index) => (
                            <TimelineHeaderCell
                                columnDuration={columnDurations[index]}
                                key={index}
                                style={{
                                    left: `${((cellWidth * index) / timelineSize) * 100}%`,
                                    right: `${((cellWidth * (columnDurations.length - index - 1)) / timelineSize) * 100}%`,
                                }}
                                timelineMode={timelineMode} />
                        ))}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default TimelineHeader;
