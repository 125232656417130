import {
    PlannerQueryParams,
    queryPlannerItemsAsync,
} from "src/api/plannerApi";

import {
    PlannerActionTypes,
    QueryPlannerItemsAction
} from "../types";

export function plannerItemCreated(item: Spintr.PlannerItem) {
    return {
        type: PlannerActionTypes.PlannerItemCreated,
        item,
    };
}

export function plannerItemUpdated(item: Spintr.PlannerItem) {
    return {
        type: PlannerActionTypes.PlannerItemUpdated,
        item,
    };
}

export function queryPlannerItems(
    params?: PlannerQueryParams | undefined,
): QueryPlannerItemsAction {
    return {
        payload: queryPlannerItemsAsync(params),
        type: PlannerActionTypes.QueryPlannerItems,
    };
}
