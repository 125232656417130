import React, { ReactElement, useMemo } from "react";
import { PlannerFileListProps } from "./PlannerFileList.types";
import { SmallBodyBolder } from "src/components/Text";

function PlannerFileList(props: PlannerFileListProps): ReactElement {
    const { fieldType, files: allFiles, itemType, title } = props;

    const files = useMemo(
        () => allFiles
            .filter((file) => file.fieldType === fieldType || file.itemType === itemType)
            .sort((a, b) => a.fileName.localeCompare(b.fileName)),
        [allFiles, fieldType, itemType],
    );

    if (files.length === 0) {
        return null;
    }

    return (
        <section className="PlannerFileList">
            <div className="PlannerFileList-header">
                <SmallBodyBolder color="contentDark">
                    {title}
                </SmallBodyBolder>
            </div>
            <div className="PlannerFileList-files">
                {files.map((file) => (
                    <div key={file.id} className="PlannerFileList-file">
                        <a
                            className="PlannerFileList-fileLink"
                            href={"#"}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {file.fileName}
                        </a>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default PlannerFileList;