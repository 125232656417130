import React, { ReactElement, useMemo } from "react";
import { TimelineCellOverlayProps } from "./TimelineCellOverlay.types";

function TimelineCellOverlay(props: TimelineCellOverlayProps): ReactElement {
    const { index, intervalCount, timelineMode, timelineWidth } = props;

    const style = useMemo(() => {
        const cellWidth = timelineMode === "DAYS" ? 128 : 255;

        return {
            left: `${((cellWidth * index) / timelineWidth) * 100}%`,
            right: `${((cellWidth * (intervalCount - index - 1)) / timelineWidth) * 100}%`,
        };
    }, [index, intervalCount, timelineMode, timelineWidth])

    return (
        <div className="TimelineCellOverlay" style={style} />
    );
}

export default TimelineCellOverlay;
