import React, { CSSProperties, ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { Conditional } from "src/components/Conditional";
import { CaptionBody, Colors } from "src/components/Text";
import { useHover } from "src/hooks/useHover";
import { TimelineBarProps, TimelineBarMutableState } from "./TimelineBar.types";
import { ColumnDuration } from "../types";
import moment from "moment";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";


function calculateBarStyle(
    timelineWidth: number,
    timelineDuration: ColumnDuration,
    start: number,
    end: number
): CSSProperties {
    const timeSinceStart = start - timelineDuration.startMilliseconds;
    const left = (timeSinceStart / timelineDuration.totalMilliseconds) * timelineWidth;

    const timeUntilEnd = timelineDuration.endMilliseconds - end;
    const right = (timeUntilEnd / timelineDuration.totalMilliseconds) * timelineWidth

    return {
        height: "42px",
        left: `${left}px`,
        right: `${right}px`,
    };
}

function snapToGrid(date: Date): Date {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();

    if (!(hours > 12 || (hours === 12 && (minutes > 0 || seconds > 0 || milliseconds > 0)))) {
        date.setDate(date.getDate() - 1);
    }
    date.setHours(23, 59, 59, 999);

    return date;
}

function formatDates(start: Date, end: Date): string {
    const startMoment = moment(start);
    const endMoment = moment(end);

    let retVal: string = startMoment.format("D");
    if (start.getFullYear() !== end.getFullYear() || start.getMonth() !== end.getMonth()) {
        retVal += ` ${startMoment.format("MMM")}`;
    }

    retVal += ` - ${endMoment.format("D MMM")}`;

    return retVal;
}

function TimelineBar(props: TimelineBarProps): ReactElement {
    const {
        axisWidth,
        item,
        onBarClick,
        onItemDurationChange,
        timelineWidth,
        timelineDuration,
        todayTime,
    } = props;

    const [hoverRef, isHoveringBar] = useHover<HTMLDivElement>();
    const refState = useRef<TimelineBarMutableState>({ resizing: "no" });
    const [_, setForcedUpdate] = useState<boolean>(false);

    const onMouseMove = useCallback((event: MouseEvent) => {
        if (!hoverRef.current || refState.current.resizing === "no") {
            return;
        }

        let scrollParent: HTMLElement = hoverRef.current;
        while (!scrollParent.classList.contains("timeline") && scrollParent.parentElement) {
            scrollParent = scrollParent.parentElement;
        }

        if (!scrollParent) {
            return;
        }

        const mouseX = event.clientX;
        const axisOfftet = axisWidth || 257;

        const scrollParentRect = scrollParent.getBoundingClientRect();
        const scrollParentLeft = scrollParentRect.left
        const scrollLeft = scrollParent.scrollLeft - axisOfftet; // remove axis width
        const positionInTimeline = Math.max(0, mouseX - scrollParentLeft + scrollLeft);
        
        const percentage = positionInTimeline / timelineWidth;

        const newDate = new Date(timelineDuration.startMilliseconds + timelineDuration.totalMilliseconds * percentage);
        const snappedDate = snapToGrid(newDate);

        if (refState.current.resizing === "right") {
            const minTime = item.start.getTime() + (1000 * 60 * 60 * 24) - 1;

            if (snappedDate.getTime() < minTime) {
                snappedDate.setTime(minTime);
            }

            refState.current.overriddenEndMs = snappedDate.getTime();
        } else if (refState.current.resizing === "left") {
            const maxTime = item.end.getTime() - (1000 * 60 * 60 * 24) + 1;

            if (snappedDate.getTime() > maxTime) {
                snappedDate.setTime(maxTime);
            }

            refState.current.overriddenStartMs = snappedDate.getTime();
        }

        if (mouseX < scrollParentRect.left + (100 + axisOfftet)) {
            scrollParent.scrollTo({ left: scrollParent.scrollLeft - 100, behavior: "smooth" });
        } else if (mouseX > scrollParentRect.right - 100) {
            scrollParent.scrollTo({ left: scrollParent.scrollLeft + 100, behavior: "smooth" });
        }

        setForcedUpdate((prev) => !prev);
    }, [refState, hoverRef.current, timelineDuration, timelineWidth, item.start, item.end, setForcedUpdate, axisWidth]);

    const onMouseUp = useCallback((_: MouseEvent) => {
        const { overriddenStartMs, overriddenEndMs } = refState.current;

        if (refState.current.resizing === "right") {
            console.log("new end: ", new Date(overriddenEndMs));
        } else if (refState.current.resizing === "left") {
            console.log("new start: ", new Date(overriddenStartMs));
        }
        
        const updatedStart = overriddenStartMs ?? item.start.getTime();
        const updatedEnd = overriddenEndMs ?? item.end.getTime();

        refState.current.resizing = "no";
        refState.current.overriddenEndMs = refState.current.overriddenStartMs = undefined;

        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);

        setForcedUpdate((prev) => !prev);
        onItemDurationChange?.(item, updatedStart, updatedEnd);
    }, [refState, onMouseMove, setForcedUpdate, item, onItemDurationChange]);

    const onResizeStart = useCallback((event: React.MouseEvent, direction: "left" | "right") => {
        if (!event) {
            return;
        }

        event.preventDefault?.();
        event.stopPropagation?.();
        
        refState.current.resizing = direction;

        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
    }, [refState, onMouseUp, onMouseMove]);

    const onBarClicked = useCallback(
        () => onBarClick?.(item),
        [item],
    );

    const onLeftHandleDragStart = useCallback((event: React.MouseEvent) => onResizeStart(event, "left"), [onResizeStart]);
    const onRightHandleDragStart = useCallback((event: React.MouseEvent) => onResizeStart(event, "right"), [onResizeStart]);

    const barStyle = useMemo<CSSProperties>(() => calculateBarStyle(
        timelineWidth,
        timelineDuration,
        refState.current.overriddenStartMs ?? item.start.getTime(),
        refState.current.overriddenEndMs ?? item.end.getTime(),
    ), [timelineWidth, timelineDuration, item, refState.current.overriddenEndMs, refState.current.overriddenStartMs]);

    const progressStyle = useMemo<CSSProperties>(() => {
        const cssProperties: CSSProperties = {
            backgroundColor: item.color,
        };

        const startTime = refState.current.overriddenStartMs ?? item.start.getTime();
        if (startTime > todayTime) {
            return cssProperties;
        }

        const endTime = refState.current.overriddenEndMs ?? item.end.getTime();
        if (todayTime > endTime) {
            cssProperties.borderTopRightRadius = "8px";
            cssProperties.borderBottomRightRadius = "8px";
            cssProperties.right = 0;
            return cssProperties;
        }

        const timeSinceStart = todayTime - startTime;
        const pixelWidth = (timeSinceStart / timelineDuration.totalMilliseconds) * timelineWidth;

        cssProperties.width = `${pixelWidth + 1}px`;

        return cssProperties;
    }, [item, todayTime, refState.current.overriddenEndMs, refState.current.overriddenStartMs, timelineWidth, timelineDuration.totalMilliseconds]);

    const textColor = useMemo<Colors>(
        () => getLightOrDarkColorBasedOnColor(
            item.color,
            "white",
            "contentDark"
        ) as Colors,
        [item.color],
    );

    const dateColor = useMemo<Colors>(
        () => getLightOrDarkColorBasedOnColor(
            item.color,
            "white",
            "contentNormal"
        ) as Colors,
        [item.color],
    );

    return (
        <div
            className="TimelineBar"
            ref={hoverRef}
            style={barStyle}
        >
            <div
                className="TimelineBar-inner"
                onClick={onBarClicked}
                style={{ backgroundColor: item.color + "99" }}
            >
                <CaptionBody
                    className="TimelineBar-label-top"
                    color={textColor}
                    weight="medium"
                >
                    {props.item.name}
                </CaptionBody>
                <div className="TimelineBar-label-bottom">
                    <CaptionBody color={dateColor} weight="regular">
                        {formatDates(item.start, item.end)}
                    </CaptionBody>
                </div>
            </div>

            <div className="TimelineBar-progressTracker" style={progressStyle} />

            <Conditional condition={isHoveringBar || refState.current.resizing !== "no"}>
                <div
                    className="TimelineBar-barHandle left"
                    onMouseDown={onLeftHandleDragStart} />
                <div
                    className="TimelineBar-barHandle right"
                    onMouseDown={onRightHandleDragStart} />
            </Conditional>
        </div>
    );
}

export default TimelineBar;
