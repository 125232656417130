import React, { CSSProperties, ReactElement, useMemo } from "react";
import { TimelineRowProps } from "./TimelineRow.types";
import { TimelineBar } from "../TimelineBar";
import { SmallBody } from "src/components/Text";

function PlannerTimelineRow(props: TimelineRowProps): ReactElement {
    const {
        item,
        onBarClick,
        onItemDurationChange,
        timelineSize,
        timelineDuration,
        todayTime,
    } = props;

    const railStyle = useMemo<CSSProperties>(
        () => ({
            width: `${timelineSize}px`,
        }),
        [timelineSize],
    );

    return (
        <section
            aria-rowindex={props.rowIndex}
            className="TimelineRow"
            role="row"
            style={props.style}
        >
            <div className="TimelineRow-label" role="gridcell">
                <SmallBody
                    className="TimelineRow-labelText"
                    color="contentNormal"
                    title={item.name}
                    weight="regular"
                >
                    {item.name}
                </SmallBody>
            </div>

            <div
                className="TimelineRow-rail"
                role="gridcell"
                style={railStyle}
            >
                <TimelineBar
                    axisWidth={props.axisWidth}
                    item={item}
                    onBarClick={onBarClick}
                    onItemDurationChange={onItemDurationChange}
                    timelineDuration={timelineDuration}
                    timelineWidth={timelineSize}
                    todayTime={todayTime} />
            </div>
        </section>
    );
}

export default PlannerTimelineRow;
