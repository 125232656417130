import { combineReducers } from "redux";

import appReducer, { IAppsState } from "src/app/reducer";
import assistantReducer, { IAssistantState } from "src/assistant/reducer";
import authReducer, { IAuthState } from "src/auth/reducer";
import blogsReducer from "src/blogs/reducer";
import calendarReducer, { ICalendarState} from "src/calendar/reducer";
import { chatReducer, IChatState } from "src/chat/redux";
import emergencyReducer from "src/emergency-alerts/reducer";
import { favoriteReducer, IFavoriteState } from "src/favorites/redux";
import filesReducer, { IFileState } from "src/files/reducer";
import groupsReducer from "src/groups/reducer";
import instanceReducer from "src/instance/reducer";
import { IInteractionsState, interactionsReducer } from "src/interactions/reducer";
import mediaReducer from "src/media/reducer";
import menuReducer, { IMenuState} from "src/menu/reducer";
import notesReducer from "src/notes/reducer";
import notificationReducer, { INotificationsState } from "src/notifications/reducer";
import operatingInfoReducer, { IOperatingInformationState} from "src/operating-info/reducer";
import popupReducer, { IPopupState } from "src/popups/reducer";
import profileReducer, { IProfileState} from "src/profile/reducer";
import { ISearchState, searchReducer } from "src/search";
import { ISocialFeedState, SocialFeedReducer } from "src/social-feed";
import { ITeaserBoxState, teaserboxesReducer} from "src/teaser-box";
import uiReducer, { IUiState } from "src/ui/reducer";
import usersReducer from "src/users/reducer";
import privilegesReducer from "src/privileges/reducer";
import teamsReducer from "src/teams/reducer";
import shortcutsReducer, { IShortcutsState } from "src/shortcuts/reducer";
import spotlightReducer, { ISpotlightState } from "src/news/components/Spotlight/reducer";
import informationFeedReducer, { IInformationFeedState } from "./components/InformationFeed/reducer";
import { ISidebarState, sidebarReducer } from "src/sidebar";
import { IRightColumnState, rightColumnReducer } from "src/right-column";
import { IPageState, pageReducer } from "src/pages/page-reducer";
import { plannerReducer, PlannerState } from "src/redux";

export interface IApplicationState {
    app: IAppsState;
    assistant: IAssistantState;
    auth: IAuthState;
    blogs: any;
    calendar: ICalendarState;
    chat: IChatState;
    favorites: IFavoriteState,
    files: IFileState;
    groups: any;
    instance: any;
    interactions: IInteractionsState;
    media: any;
    menu: IMenuState;
    notes: any;
    notification: INotificationsState;
    operatingInfo: IOperatingInformationState;
    pages: IPageState;
    people: any;
    planner: PlannerState;
    popups: IPopupState;
    profile: IProfileState;
    search: ISearchState;
    socialFeed: ISocialFeedState;
    teaserboxes: ITeaserBoxState;
    ui: IUiState;
    emergency: any;
    privileges: any;
    teams: any;
    shortcuts: IShortcutsState;
    spotlight: ISpotlightState;
    informationFeed: IInformationFeedState;
    sidebar: ISidebarState;
    rightColumn: IRightColumnState;
}

const getRootReducer = () => {
    return combineReducers({
        app: appReducer,
        assistant: assistantReducer,
        auth: authReducer,
        blogs: blogsReducer,
        calendar: calendarReducer,
        chat: chatReducer,
        emergency: emergencyReducer,
        favorites: favoriteReducer,
        files: filesReducer,
        groups: groupsReducer,
        instance: instanceReducer,
        interactions: interactionsReducer,
        media: mediaReducer,
        menu: menuReducer,
        notes: notesReducer,
        notification: notificationReducer,
        operatingInfo: operatingInfoReducer,
        people: usersReducer,
        planner: plannerReducer,
        popups: popupReducer,
        profile: profileReducer,
        search: searchReducer,
        socialFeed: SocialFeedReducer,
        teaserboxes: teaserboxesReducer,
        ui: uiReducer,
        privileges: privilegesReducer,
        teams: teamsReducer,
        shortcuts: shortcutsReducer,
        spotlight: spotlightReducer,
        informationFeed: informationFeedReducer,
        sidebar: sidebarReducer,
        rightColumn: rightColumnReducer,
        pages: pageReducer
    });
};

export default getRootReducer;
