import {
    Dropdown,
    Icon,
    Icon as FabricIcon,
    IDropdownOption,
    Pivot,
    PivotItem,
    Stack,
    Text,
    FontIcon,
    Callout,
    DirectionalHint,
} from "@fluentui/react";
import axios, { CancelTokenSource } from "axios";
import { Location } from "history";
import qs from "qs";
import React, {
    ChangeEvent,
    Component,
    createRef,
    FocusEvent,
    FormEvent,
    KeyboardEvent,
    ReactNode,
    RefObject
} from "react";
import { connect, DispatchProp } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { from, of, Subject, Subscription } from "rxjs";
import { catchError, debounceTime } from "rxjs/operators";
import { appInsights } from "src/AppInsights";
import { getTypeName, localize, localizeFormat } from "src/l10n";
import { searchFiles } from "src/office365";
import { SearchResultObjectHit } from "src/search";
import {
    ClearResultsAction,
    clearSearchResults,
    ExecuteSearchAction,
    executeSearchAction
} from "src/search/actions";
import { ISearchParams, submitFeedback } from "src/search/api";
import { ISearchFeedback } from "src/search/types";
import { ISearchHeaders } from "src/search/utils";
import { SpintrTypes } from "src/typings";
import {
    Label,
    Loader,
    setDisplayHeaderSearch,
    SetDisplayHeaderSearch,
    setHeaderSearchBarMode,
    SetHeaderSearchBarModeHandler,
    setOverlayId,
    SpintrUser,
    UnstyledButton,
} from "src/ui";
import {
    capitalizeFirstLetter,
    getQueryStringMap,
    mixpanelTrack,
    parentBy
} from "src/utils";
import api from "src/spintr/SpintrApi";
import SpintrOverlayToggler from "src/spintr/components/SpintrOverlayToggler";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import Color from "src/style/colors/Color";
// import VisageIcon from "src/ui/components/Icon/VisageIcon";
// import magnifyingglass from "src/animated-icons/magnifyingglass.json";
// import AnimatedIcon from "src/animated-icons/AnimatedIcon";
import { AssistantWindow } from "src/assistant";
import classNames from "classnames";
import { SetActiveSubjectDateHandler, SetActiveSubjectsHandler, setActiveSubjectDate, setActiveSubjects } from "src/assistant/actions";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

/**
 * The threshold in ms for submitting search feedback
 */
const SearchFeedbackThreshold: number = 2500;

interface IOwnprops {
    isModal?: boolean;
}

interface IDispatchProps {
    clearSearchResults?: ClearResultsAction;
    executeSearchAction?: ExecuteSearchAction;
    setDisplayHeaderSearch?: SetDisplayHeaderSearch;
    setHeaderSearchBarMode?: SetHeaderSearchBarModeHandler;
    setActiveSubjects?: SetActiveSubjectsHandler;
    setActiveSubjectDate?: SetActiveSubjectDateHandler;
    setOverlayId?: any;
}

interface IAIAssistantProps {
    enabled: boolean;
    name: string;
    imageUrl?: string;
    color?: string;
    contrastColor?: string;
}

interface IStateProps {
    displayHeaderSearch: boolean;
    aiAssistant: IAIAssistantProps;
    errorMessageTag?: string;
    headers?: ISearchHeaders;
    isLoading: boolean;
    lastParams?: ISearchParams;
    isActive?: boolean;
    mode: SpintrTypes.HeaderSearchBarMode;
    office365Enabled: boolean;
    results?: Spintr.ISearchResult[];
    bansheeEnabled?: boolean;
    useColorHeader?: boolean;
    instanceColor?: string;
    isAdmin?: boolean;
    isBetaTester?: boolean;
    currentUserName?: string;
    activeSubjects?: string[];
}

interface IState {
    aiAssistantActive?: boolean;
    groupId?: number;
    isActive: boolean;
    oneDriveResults?: Spintr.IOffice365FileSearchEnvelope;
    queryText: string;
    sharepointResults?: Spintr.IOffice365FileSearchEnvelope;
    typeId: SpintrTypes.UberType;
    isLoadingOffice365: boolean;
    bansheeResults?: any;
}

type Props = IDispatchProps & IStateProps & DispatchProp & RouteComponentProps & IOwnprops;

class SpintrHeaderSearch extends Component<Props, IState> {
    protected readonly querySubject: Subject<string>;
    protected readonly querySubscription: Subscription;
    protected office365Subscription: Subscription | null;

    protected bansheeSubscription: Subscription | null;

    protected lastSearch?: Date;
    protected inputRef: RefObject<HTMLInputElement>;
    protected cancelTokenSource: CancelTokenSource;
    protected searchIconAnimationRef?;

    constructor(props: Props) {
        super(props);

        const state: IState = {
            aiAssistantActive: 
                props.aiAssistant?.enabled &&
                props.mode === SpintrTypes.HeaderSearchBarMode.Assistant &&
                props.displayHeaderSearch,
            isActive: props.isModal,
            isLoadingOffice365: false,
            queryText: "",
            typeId: SpintrTypes.UberType.Unknown,
        };

        this.inputRef = createRef();

        if (props.isModal && props.mode === SpintrTypes.HeaderSearchBarMode.Search) {
            const focusInterval = setInterval(() => {
                if (this.inputRef &&
                    this.inputRef.current) {
                    this.inputRef.current.focus();
                    clearInterval(focusInterval);
                }
            });
        }

        const onSearchPage = this.isOnSearchPage();
        if (onSearchPage) {
            const qs = getQueryStringMap(props.location);
            if (qs.q) {
                state.queryText = qs.q || "";
            }

            if (qs.g) {
                state.groupId = parseInt(qs.g, 10);
            }
        }

        this.state = state;

        this.onClearClick = this.onClearClick.bind(this);
        this.onNextQuery = this.onNextQuery.bind(this);
        this.onOffice365Fetched = this.onOffice365Fetched.bind(this);
        this.onOverlayClick = this.onOverlayClick.bind(this);
        this.onResultClick = this.onResultClick.bind(this);
        this.onQueryInputFocus = this.onQueryInputFocus.bind(this);
        this.onQueryInputKeyUp = this.onQueryInputKeyUp.bind(this);
        this.onQueryTextChange = this.onQueryTextChange.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onRelatedTermClick = this.onRelatedTermClick.bind(this);
        this.onExpertClick = this.onExpertClick.bind(this);
        this.onRecommendedContentClick = this.onRecommendedContentClick.bind(this);
        this.onContainerClick = this.onContainerClick.bind(this);
        this.onAssistantClose = this.onAssistantClose.bind(this);
        this.onSetSearchModeClick = this.onSetSearchModeClick.bind(this);
        this.onIconClick = this.onIconClick.bind(this);
        this.onSearchModeClick = this.onSearchModeClick.bind(this);

        this.querySubject = new Subject<string>();
        this.querySubscription = this.querySubject
            .pipe(debounceTime(500))
            .subscribe(this.onNextQuery);
    }

    public componentDidMount(): void {
        if (this.state.queryText.length === 0) {
            return;
        }

        this.querySubject.next(this.state.queryText);
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<IState>): void {
        const prevQuery: string = (prevProps.lastParams || {}).query;
        const nextQuery: string = (this.props.lastParams || {}).query;

        const overlayIsVisible = (prevProps.isModal && prevState.isActive && prevProps.isActive) || prevState.queryText;
        const overlayShouldBeVisible = (this.props.isModal && this.state.isActive && this.props.isActive) || this.state.queryText;

        if (overlayIsVisible !== overlayShouldBeVisible) {
            this.props.setOverlayId(overlayShouldBeVisible ? "search" : "");
        }

        if (prevQuery !== nextQuery) {
            this.setState(
                {
                    oneDriveResults: null,
                    sharepointResults: null,
                },
                () => {
                    this.executeOffice365Search();
                }
            );
        }

        const wasOnSearchPage = prevProps.location.pathname.toLowerCase().indexOf("/search") === 0;

        if (!wasOnSearchPage && this.isOnSearchPage()) {
            const qs = getQueryStringMap(this.props.location);

            if (qs) {
                const currentQuery = this.state.queryText;
                this.setState(
                    {
                        groupId: qs.g ? parseInt(qs.g, 10) : this.state.groupId,
                        queryText: qs.q || "",
                    },
                    () => {
                        if (currentQuery !== this.state.queryText) {
                            this.querySubject.next(this.state.queryText);
                        }
                    }
                );
            }

            return;
        }

        if (!wasOnSearchPage || this.isOnSearchPage()) {
            // if we weren't on search page or we still are, do nothing
            return;
        }

        // if we leave search page, clear text and results
        this.setState({ isActive: false, queryText: "", typeId: 0, bansheeResults: null }, () => this.props.clearSearchResults());
    }

    public componentWillUnmount() {
        this.props.setDisplayHeaderSearch(false);
    }

    public render(): ReactNode {
        const { errorMessageTag, isLoading, results } = this.props;

        const onSearchPage = this.isOnSearchPage();
        const showPopdown = !!errorMessageTag || isLoading || !!results;

        const hasTypes = this.props.headers && this.props.headers.objectTypes;
        const types: IDropdownOption[] = [];
        if (hasTypes) {
            Object.keys(this.props.headers.objectTypes)
                .filter((k) => !isNaN(+k))
                .map((k) => parseInt(k, 10) as SpintrTypes.UberType)
                .forEach((t) => {
                    const count = this.props.headers.objectTypes[t];

                    types.push({
                        key: t,
                        selected: t === this.state.typeId,
                        text:
                            (t === SpintrTypes.UberType.Unknown
                                ? localize("Alla")
                                : capitalizeFirstLetter(
                                    getTypeName(t, {
                                        case: "indefinite",
                                        form: "plural",
                                    })
                                )) + (!!count ? (" (" + count + ")") : ""),
                    });
                });
        }

        const placeholderText = localize("Sok") + "...";

        const modalIsOpen = this.props.isModal &&
            this.props.mode === SpintrTypes.HeaderSearchBarMode.Assistant;

        return (
            <div className={classNames("HeaderSearchWrapper", {
                "assistant-enabled": this.props.aiAssistant?.enabled,
            })}>
                {this.props.aiAssistant?.enabled && this.props.isBetaTester && !(this.props.isModal && this.props.mode === SpintrTypes.HeaderSearchBarMode.Assistant) && (
                    <div
                        className={"AssistantButton" + (!modalIsOpen ? " closed" : "")}
                        style={{
                            backgroundColor: this.props.aiAssistant.color
                        }}>
                        {this.renderAssistantPart()}
                    </div>
                )}
                <div
                    role="search"
                    className={classNames("HeaderSearch", {
                        "active": this.state.isActive,
                        "assistant-enabled": this.props.aiAssistant?.enabled,
                        "modal-mode": this.props.isModal,
                        "assistant-mode": this.props.mode === SpintrTypes.HeaderSearchBarMode.Assistant,
                        "search-mode": this.props.mode === SpintrTypes.HeaderSearchBarMode.Search,
                    })}
                    onKeyDown={(e) => {
                        if (!this.props.isModal) {
                            return;
                        }

                        var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : e.which ? e.which : 0;

                        const isTab = key === SpintrTypes.KeyCodes.Tab;

                        if (!isTab) {
                            return;
                        }

                        const activeElement = document.activeElement;

                        if (activeElement.tagName === "INPUT" && e.shiftKey) {
                            e.preventDefault();
                            e.stopPropagation();

                            const elements = document.getElementsByClassName('VisageSidebar-menuItem');

                            if (!!elements && elements.length > 0) {
                                const element = elements[elements.length - 1];

                                if (element) {
                                    //@ts-ignore
                                    element.focus();
                                }
                            }

                            this.props.setDisplayHeaderSearch(false);
                        }

                        if ((!e.shiftKey && this.inputRef.current.value === "") || (activeElement.className.indexOf("show-all-link") > -1 && !e.shiftKey)) {
                            e.preventDefault();
                            e.stopPropagation();

                            const elements = document.getElementsByClassName('SpintrStaticLinks');

                            if (!!elements && elements.length > 0) {
                                const children = elements[0].childNodes;

                                if (!!children && children.length > 0) {
                                    const element = children[0];

                                    if (element) {
                                        //@ts-ignore
                                        element.focus();
                                    }
                                }
                            }

                            this.props.setDisplayHeaderSearch(false);
                        }

                        if (e.shiftKey && this.inputRef.current.value === "") {
                            e.preventDefault();
                            e.stopPropagation();

                            // FIXME: Focus the previous element in the DOM
                            document.getElementById("Changelog")?.focus();
                        }
                    }}>
                    {/* {this.props.isModal && this.state.isActive && this.props.isActive && (
                        <div className="SpintrOverlay" onClick={this.onOverlayClick.bind(this)} />
                    )} */}
                    <div className="wrapper">
                        <div className="search-bar" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                            <div
                                className="input-container"
                                style={{
                                    backgroundColor: this.props.isModal && this.props.mode === SpintrTypes.HeaderSearchBarMode.Assistant ? this.props.aiAssistant.color : undefined
                                }}
                            >
                                <div className="wrap">
                                    {this.props.isModal && this.props.mode === SpintrTypes.HeaderSearchBarMode.Assistant && this.renderAssistantPartInner()}
                                    {/* <Visage2Icon icon="search-normal-1" color="dark-grey" /> */}
                                    <input
                                        aria-label={placeholderText}
                                        className="query-input"
                                        onChange={this.onQueryTextChange}
                                        onFocus={this.onQueryInputFocus}
                                        onKeyUp={this.onQueryInputKeyUp}
                                        onBlur={this.handleBlur}
                                        placeholder={placeholderText}
                                        ref={this.inputRef}
                                        type="text"
                                        value={this.state.queryText}
                                    />
                                    {this.state.isActive && types.length > 0 && (
                                        <Dropdown
                                            aria-label={localize("FiltreraVyn")}
                                            className="type-selector"
                                            onChange={this.onTypeChange}
                                            options={types}
                                            dropdownWidth={200}
                                        />
                                    )}
                                </div>
                                {this.state.queryText.length > 0 && this.props.mode === SpintrTypes.HeaderSearchBarMode.Search && (
                                    <a className="clear-button" onClick={this.onClearClick}>
                                        <Icon iconName="Cancel" />
                                    </a>
                                )}
                            </div>
                            {this.state.isActive && !onSearchPage && this.props.isActive && (
                                <Callout
                                    role="alertdialog"
                                    target=".search-field"
                                    className="search-callout"
                                    gapSpace={23}
                                    setInitialFocus
                                    calloutWidth={800}
                                    directionalHint={DirectionalHint.bottomCenter}
                                    onDismiss={() => {
                                        this.onOverlayClick();

                                        this.setState({
                                            isActive: false
                                        });
                                    }}
                                >
                                    <div className={"popdown" + (showPopdown ? "" : " hidden")} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>
                                        {this.renderPopdownContent(types)}
                                    </div>
                                </Callout>
                            )}
                            { this.props.mode === SpintrTypes.HeaderSearchBarMode.Assistant
                            && this.state.aiAssistantActive 
                            && this.props.isModal
                            && (
                                <div
                                    className="popdown"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                }>
                                    <AssistantWindow
                                        assistantName={this.props.aiAssistant?.name || ""}
                                        assistantImage={this.props.aiAssistant?.imageUrl}
                                        onClose={this.onAssistantClose}
                                    />
                                </div>

                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public shouldComponentUpdate(nextProps: Props): boolean {
        // State update, please rerender
        if (nextProps === this.props) {
            return true;
        }

        // The role of the header has changed
        if (this.props.mode !== nextProps.mode || this.props.displayHeaderSearch !== nextProps.displayHeaderSearch) {
            return true;
        }

        const nextQuery: string = (nextProps.lastParams || {}).query;
        const prevQuery: string = (this.props.lastParams || {}).query;

        // Query changed, please rerender
        if (prevQuery !== nextQuery) {
            return true;
        }

        // Not a navigation update, please rerender
        if (nextProps.location.key === this.props.location.key) {
            return true;
        }

        // Only rerender on location change if active
        // or if we leave or enter search page
        return this.state.isActive || this.isOnSearchPage() || this.isOnSearchPage(nextProps.location);
    }

    protected onSearchModeClick(): void {
        this.props.setHeaderSearchBarMode(SpintrTypes.HeaderSearchBarMode.Search);
        this.props.setDisplayHeaderSearch(true);
    }

    protected joinSubjects(subjects: string[]) {
        const slicedArray = subjects.slice(0, subjects.length < 5 ? subjects.length : 4);

        let s = "";

        if (slicedArray.length === 1) {
            s =  slicedArray[0];
        } else if (subjects.length === 2) {
            s =  slicedArray[0] + " " + localize("Och_small") + " " + slicedArray[1];
        } else {
            let s = "";

            for (let i = 0; i < slicedArray.length; i++) {
                if (s) {
                    s += (i === (slicedArray.length - 1) ? (" " + localize("Och_small") + " ") : ", ");
                }

                s+= slicedArray[i];
            }
        }

        return s;
    }

    protected renderAssistantPartInner(): React.ReactNode {
        const modalIsOpen = this.props.isModal &&
            this.props.mode === SpintrTypes.HeaderSearchBarMode.Assistant;

        const modalIsOpenWithSubjects = this.props.isModal &&
            this.props.mode === SpintrTypes.HeaderSearchBarMode.Assistant &&
            this.props.activeSubjects &&
            this.props.activeSubjects.length > 0;

        let s = "";

        if (!modalIsOpen) {
            s = localizeFormat("AI_ASSISTANT_ASK_F", this.props.aiAssistant.name);
        } else if (!modalIsOpenWithSubjects) {
            s = localizeFormat("AI_ASSISTANT_USER_GREETING1_F", this.props.currentUserName.split(' ').slice(0, 1).join("")) + ", " + localize("AI_ASSISTANT_USER_GREETING2");
        } else {
            s = localize("AI_ASSISTANT_SUBJECTS")
                .replace("{0}", "<span>" + this.joinSubjects(this.props.activeSubjects) + "</span>")
                .replace("{1}", "<span>" + localize("AI_ASSISTANT_SUBJECTS_NEW_CONVERSATION") + "</span>")
        }

        return (
            <div className={"AssistantPartInner"}>
                <div className="assistant-icon">
                    <img
                        alt={this.props.aiAssistant.name}
                        height={28}
                        onClick={this.onIconClick}
                        src={this.props.aiAssistant.imageUrl || "/Style/Images/ai-assistant.png"}
                        width={28}
                    />
                </div>
                <UnstyledButton
                    className="assistant-cta-wrapper"
                    disabled={!modalIsOpenWithSubjects}
                    onClick={() => {
                        this.props.setActiveSubjects([]);
                        this.props.setActiveSubjectDate(new Date());
                    }}>
                    <Label
                        className={"assistant-cta" + (modalIsOpenWithSubjects ? " has-subjects" : "")}
                        size="body-2"
                        style={this.props.aiAssistant?.contrastColor 
                            ? { color: this.props.aiAssistant?.contrastColor || "#000000" }
                            : undefined
                        }
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: s,
                            }}
                        />
                    </Label>
                </UnstyledButton>
                {this.props.isModal && this.props.mode === SpintrTypes.HeaderSearchBarMode.Assistant && (
                    <UnstyledButton className="close-button" title={localize("Stang")} onClick={() => {
                        this.onOverlayClick();
                    }}>
                        <FontIcon iconName="ChromeClose" style={{ color: this.props.aiAssistant?.contrastColor || "#FFFFFF" }} />
                    </UnstyledButton>
                )}
            </div>
        )
    }

    protected renderAssistantPart(): React.ReactNode {
        if (!this.props.aiAssistant?.enabled || !this.props.isBetaTester) {
            return null;
        }

        return (
            <div className={"assistant-wrapper"} onClick={this.onContainerClick} id="ai-assistant-wrapper">
                {this.renderAssistantPartInner()}
            </div>
        )
    }

    protected onIconClick(event: React.MouseEvent): void {
        if (!this.props.aiAssistant?.enabled) {
            return;
        }

        event?.preventDefault && event.preventDefault();
        event?.stopPropagation && event.stopPropagation();

        this.props.clearSearchResults();
        this.props.setDisplayHeaderSearch(true);
        this.props.setHeaderSearchBarMode(SpintrTypes.HeaderSearchBarMode.Assistant);

        this.setState({
            aiAssistantActive: true,
        }, () => {
        });
    }

    protected onSetSearchModeClick(event: React.MouseEvent): void {
        event?.preventDefault && event.preventDefault();
        event?.stopPropagation && event.stopPropagation();

        this.setState((currentState) => ({
            ...currentState,
            aiAssistantActive: false,
        }), () => {
            this.props.setHeaderSearchBarMode(SpintrTypes.HeaderSearchBarMode.Search);
            this.props.setDisplayHeaderSearch(true);

            if (!this.inputRef?.current) {
                return;
            }

            this.inputRef.current.focus();
        });
    }

    protected onContainerClick(event: React.MouseEvent): void {    
        if (!this.props.aiAssistant?.enabled) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        this.props.clearSearchResults();
        this.props.setDisplayHeaderSearch(true);
        this.props.setHeaderSearchBarMode(SpintrTypes.HeaderSearchBarMode.Assistant);
        this.setState({ aiAssistantActive: true });
    }

    protected executeOffice365Search(): void {
        if (!this.props.office365Enabled) {
            return;
        }

        if (!this.props.lastParams || !this.props.lastParams.query) {
            return;
        }

        if (this.office365Subscription) {
            this.office365Subscription.unsubscribe();
            this.office365Subscription = null;
        }

        const promises: Array<Promise<Spintr.IOffice365FileSearchEnvelope>> = [
            searchFiles({
                ignoreEmptyQuery: true,
                orderByLatest: false,
                query: this.props.lastParams?.query,
                serviceType: 1,
                skip: this.state.oneDriveResults?.items.length || 0,
                take: 100,
            }),
            searchFiles({
                ignoreEmptyQuery: true,
                orderByLatest: false,
                query: this.props.lastParams!.query,
                serviceType: 2,
                skip: this.state.sharepointResults?.items.length || 0,
                take: 100,
            }),
        ];

        this.setState({ isLoadingOffice365: true }, () => {
            from(Promise.all(promises))
                .pipe(catchError((err) => of(null)))
                .subscribe(this.onOffice365Fetched);
        });
    }

    protected isOnSearchPage(location?: Location<{}>): boolean {
        location = location || this.props.location;

        return location.pathname.toLowerCase().indexOf("/search") === 0;
    }

    protected onClearClick(): void {
        this.setState({ queryText: "", typeId: 0 }, () => {
            this.querySubject.next("");

            if (this.inputRef.current) {
                this.inputRef.current.focus();
            }
        });
    }

    protected async onNextQuery(query: string): Promise<void> {
        const now: Date = new Date();
        if (this.lastSearch && now.getTime() - this.lastSearch.getTime() >= SearchFeedbackThreshold) {
            const lastQuery = this.props.lastParams?.query;
            const totalHits = this.props.headers?.totalCount || 0;

            if (lastQuery && lastQuery !== query) {
                submitFeedback({ query: lastQuery, totalHits });
            }
        }

        if (query.length === 0) {
            this.setState({ bansheeResults: null });
            if (this.props.clearSearchResults) {
                this.props.clearSearchResults();
            }
            return;
        }

        this.executeSearch(now);
    }

    protected onTypeChange(_: FormEvent<HTMLDivElement>, option: IDropdownOption): void {
        if (!option) {
            return;
        }

        const typeId = typeof option.key === "number" ? option.key : parseInt(option.key, 10);

        if (isNaN(typeId)) {
            return;
        }

        this.setState({ typeId }, () => this.executeSearch(new Date()));
    }

    protected async executeSearch(now: Date = null): Promise<void> {
        if (!this.props.executeSearchAction) {
            return;
        }

        const lastParams = this.props.lastParams || {};

        appInsights.trackEvent({
            name: "Search",
        });

        if (typeof this.cancelTokenSource !== typeof undefined) {
            this.cancelTokenSource.cancel("Operation canceled due to new request.")
        }

        this.cancelTokenSource = axios.CancelToken.source();

        await this.props.executeSearchAction({
            sort: 0,

            ...lastParams,

            groupId: this.state.groupId,
            objectType: this.state.typeId,
            query: this.state.queryText,
            take: 6,
        }, this.cancelTokenSource.token);

        if (this.props.bansheeEnabled) {
            if (this.bansheeSubscription) {
                this.bansheeSubscription.unsubscribe();
            }

            const promise = axios.get("/api/v1/insights/related", {
                params: {
                    keywords: this.state.queryText
                        .split(' ')
                        .filter(s => s),
                },
                paramsSerializer: params => qs.stringify(
                    params, {
                    arrayFormat: "indices",
                },
                ),
            });

            this.bansheeSubscription = from(promise).subscribe({
                error: (error) => {
                    if (window.location.host === "localhost") {
                        console.log(error);
                    }
                },
                next: (response) => {
                    this.setState({
                        bansheeResults: response.data,
                    });
                },
            });
        }

        if (!this.lastSearch || now > this.lastSearch) {
            this.lastSearch = now;
        }

        if (this.props.location.pathname === "/") { // start page
            mixpanelTrack("Search");
        }
    }

    protected onOffice365Fetched(envelopes: Spintr.IOffice365FileSearchEnvelope[]): void {
        if (envelopes === null) {
            // we caught a HTTP error
            return;
        }
        const oneDrive = envelopes.find((envelope) => envelope.serviceType === 1);
        const sharepoint = envelopes.find((envelope) => envelope.serviceType === 2);

        this.setState({
            isLoadingOffice365: false,
            oneDriveResults: oneDrive
                ? {
                    ...oneDrive,

                    items: (this.state.oneDriveResults?.items || []).concat(oneDrive.items),
                }
                : this.state.oneDriveResults,
            sharepointResults: sharepoint
                ? {
                    ...sharepoint,

                    items: (this.state.sharepointResults?.items || []).concat(sharepoint.items),
                }
                : this.state.sharepointResults,
        });
    }

    protected onOverlayClick(): void {
        this.setState({
            bansheeResults: null,
            isActive: false,
            queryText: "",
            typeId: 0,
            aiAssistantActive: false,
        }, () => {
            this.props.clearSearchResults();
            this.props.setDisplayHeaderSearch(false);
        });
    }

    protected onQueryInputFocus(): void {
        this.props.setHeaderSearchBarMode(SpintrTypes.HeaderSearchBarMode.Search);

        if (this.searchIconAnimationRef) {
            this.searchIconAnimationRef?.play();
        }

        if (this.isOnSearchPage()) {
            this.setState({ isActive: true });
        } else {
            this.props.setDisplayHeaderSearch(true);
        }
    }

    protected onAssistantClose() {
        this.setState((currentState) => ({
            ...currentState,
            aiAssistantActive: false,
        }));
    }

    protected onQueryInputKeyUp(event: KeyboardEvent<HTMLInputElement>): void {
        if (this.props.isModal && event.keyCode === SpintrTypes.KeyCodes.Escape) {
            this.onOverlayClick();
            return;
        }

        if (event.keyCode !== SpintrTypes.KeyCodes.Enter) {
            return;
        }

        event.preventDefault();

        this.props.history.push({
            pathname: "/search",
            search: "?q=" + encodeURIComponent(this.state.queryText),
        });
    }

    protected handleBlur(event: FocusEvent<HTMLInputElement>) {
        return;
        // const ownElement = findDOMNode(this);

        // const isOnSearchPage = this.props.location.pathname.toLowerCase().indexOf("/search") === 0;

        // if (isOnSearchPage) {
        //     console.log("tried to blur on search page");
        //     return;
        // }

        // setTimeout(() => {
        //     const parent = parentBy(window.document.activeElement as HTMLElement, (p) => p === ownElement);

        //     if (parent) {
        //         return;
        //     }

        //     const isOnSearchPageAgain = this.props.location.pathname.toLowerCase().indexOf("/search") === 0;

        //     if (isOnSearchPageAgain) {
        //         return;
        //     }

        //     this.onOverlayClick();
        // }, 100);
    }

    protected onResultClick(item: Spintr.ISearchResult): void {
        if (!item || !this.props.lastParams || !this.props.headers) {
            return;
        }

        if (this.props.location.pathname === "/") { // start page
            mixpanelTrack("Search item clicked");
        }

        const isInternal = item.url.startsWith(window.location.origin) || item.url.startsWith("/");

        if (isInternal) {
            this.props.history.push(item.url);
        } else {
            window.open(item.url);
        }

        const feedback: ISearchFeedback = {
            objectId: item.id,
            query: this.props.lastParams.query,
            totalHits: this.props.headers.totalCount,
        };

        this.setState({ queryText: "", isActive: false, typeId: 0, bansheeResults: null }, () => {
            submitFeedback(feedback);

            this.props.clearSearchResults();
        });
    }

    protected onShowInternalHitsClick() {
        mixpanelTrack("Search - Clicked show hits from intranet");
    }

    protected onShow365ResultsClick() {
        mixpanelTrack("Search - Clicked show Office 365 results");
    }

    protected onQueryTextChange(event: ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;

        this.setState({ queryText: value, isActive: true }, () => this.querySubject.next(value));
    }

    protected onRelatedTermClick(term: string): void {
        api.post("/api/v1/startdeliver/insights", { type: 2 }).catch(() => {/* noop */});

        this.setState({ queryText: term }, () => this.querySubject.next(term));
    }

    protected onExpertClick(): void {
        api.post("/api/v1/startdeliver/insights", { type: 3 }).catch(() => {/* noop */});

        this.onOverlayClick();
    }

    protected onRecommendedContentClick(): void {
        api.post("/api/v1/startdeliver/insights", { type: 4 }).catch(() => {/* noop */});

        this.onOverlayClick();
    }

    protected renderPopdownContent(types: any[]): ReactNode {
        const { errorMessageTag, isLoading, results } = this.props;

        if (isLoading) {
            return <Loader />;
        }

        if (errorMessageTag) {
            return (
                <div className="error">
                    <Text>{localize(errorMessageTag)}</Text>
                </div>
            );
        }

        const noContent = (
            <div className="empty-set">
                <Text>{localize("IngaTraffar")}</Text>
            </div>
        );

        if (!results) {
            return noContent;
        }

        const bansheeEnabled = this.props.bansheeEnabled;
        const bansheeResults = this.state.bansheeResults;
        const displayBanshee = bansheeEnabled && !!bansheeResults;

        if (types.length === 0 && results.length === 0 && !displayBanshee) {
            return noContent;
        }

        const totalHits = (this.props.headers ? this.props.headers.totalCount : 0) || 0;

        const office365Hits =
            ((this.state.oneDriveResults || {}).items || []).length +
            ((this.state.sharepointResults || {}).items || []).length;

        const office365HasMore = (
            ((this.state.oneDriveResults || { hasMore: false }).hasMore) ||
            ((this.state.sharepointResults || { hasMore: false }).hasMore)
        )

        const clickedUser = userId => {
            const feedback: ISearchFeedback = {
                objectId: userId,
                query: this.props.lastParams.query,
                totalHits: this.props.headers.totalCount
            };

            submitFeedback(feedback);
            this.onOverlayClick();
        }

        // TODO: Implement proper Banshee + user results check here 
        const hasSidebarContent = (
            (bansheeResults && (
                bansheeResults.experts?.length > 0 ||
                bansheeResults.documents?.length > 0 ||
                bansheeResults.expandedSet?.length > 0
            ))
        );

        return (
            <div className={"results" + (hasSidebarContent ? " has-sidebar" : "")}>
                <Pivot
                    className="pivot-compact-style"
                    overflowBehavior={"menu"}
                    defaultSelectedKey={"0"}
                    selectedKey={this.state.typeId.toString()}
                    onLinkClick={(item, ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();

                        const typeId = parseInt(item.props.itemKey);

                        this.setState({ typeId }, () => this.executeSearch(new Date()));
                    }}
                >
                    {types.map((type) => (
                        <PivotItem
                            key={type.key.toString()}
                            itemKey={type.key.toString()}
                            headerText={type.text}
                        />
                    ))}
                </Pivot>
                <div className="hits">
                    {results.length === 0 && noContent}
                    {!!types && !!types.length && types.length > 0 && results.length > 0 && (
                        <div className="content-wrapper">
                            {/* <div className="heading">
                                <Text variant="tiny">{localize("Traffar")}</Text>
                            </div> */}
                            <ul className="list">
                                {results.slice(0, 5).map((result) => (
                                    <li className="item" key={`HeaderSearch.Result${result.id}`}>
                                        <SearchResultObjectHit
                                            item={result}
                                            onClick={this.onResultClick.bind(this)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {hasSidebarContent && (
                        <div className="sidebar">
                            {bansheeEnabled && bansheeResults && bansheeResults.expandedSet && (
                                <div className="related">
                                    <div className="heading">
                                        <Text variant="tiny">
                                            {localize("RELATED_SEARCH_PHRASES")}
                                        </Text>
                                    </div>
                                    <ul className="list">
                                        {bansheeResults.expandedSet.map((word: string) => (
                                            <li className="item related-word" key={word}>
                                                <UnstyledButton
                                                    className="clickable-word"
                                                    onClick={this.onRelatedTermClick}
                                                    onClickData={word}
                                                >
                                                    <Text variant="medium" as="div">
                                                        {word.substring(0, 1).toUpperCase() + word.substring(1)}
                                                    </Text>
                                                </UnstyledButton>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {bansheeEnabled && bansheeResults?.experts?.length > 0 && ( // TODO: Check Experts
                                <div className="experts">
                                    <div className="heading">
                                        <Text variant="tiny">
                                            {localizeFormat(
                                                "QUESTIONS_ABOUT_X",
                                                this.props.lastParams?.query || ""
                                            )}
                                        </Text>
                                    </div>
                                    <ul className="list">
                                        {bansheeEnabled && bansheeResults.experts.slice(0, 3).map((result: any) => (
                                            <li className="item" key={`HeaderSearch.Result${result.id}`}>
                                                <div className="user">
                                                    <SpintrUser
                                                        onClick={this.onExpertClick}
                                                        imageUrl={result.imageUrl}
                                                        name={result.name}
                                                        nameLink={result.url}
                                                        subText={result.departmentName}
                                                        personalName
                                                    />
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {bansheeEnabled && bansheeResults?.documents?.length > 0 && (
                                <div className="recommended">
                                    <div className="heading">
                                        <Text variant="tiny">
                                            {localize("RECOMMENDED_CONTENT")}
                                        </Text>
                                    </div>
                                    <ul className="list">
                                        {bansheeEnabled && bansheeResults.documents.slice(0, 3).map((result: any) => (
                                            <li className="item" key={`HeaderSearch.BansheeResult${result.systemId}`}>
                                                <Link
                                                    onClick={this.onRecommendedContentClick}
                                                    to={`/goto/${result.type}/${result.systemId}`}
                                                >
                                                    <div className="document">
                                                        <Stack>
                                                            <Text variant="tiny" className="type">
                                                                {getTypeName(result.type, {
                                                                    case: "lemma",
                                                                })}
                                                            </Text>
                                                            <Text variant="medium">
                                                                {result.name}
                                                            </Text>
                                                        </Stack>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}
                    {/* TODO: Office365 */}
                </div>
                {!!types && !!types.length && types.length > 0 && results.length > 0 && (
                    <div className="footer">
                        <div className="intranet-count count">
                            <Link className="show-all-link" to={
                                "/search?q=" + encodeURIComponent(this.state.queryText)
                            }
                                onClick={this.onShowInternalHitsClick}>
                                <Label
                                    className="underline"
                                    as="span"
                                    size="body-2"
                                >
                                    {localize("GROUP_SEARCH_SHOW_ALL")} ({totalHits})
                                </Label>
                            </Link>
                        </div>
                        {(office365Hits > 0 || this.state.isLoadingOffice365) && (
                            <div className="office365-count count">
                                <Link to={
                                    "/search?s=365&q=" + encodeURIComponent(this.state.queryText)
                                }
                                    onClick={this.onShow365ResultsClick}>
                                    <Icon
                                        iconName="OfficeLogo"
                                    />
                                    <Label
                                        className={this.state.isLoadingOffice365 ? "" : "underline"}
                                        as="span"
                                        size="body-2"
                                    >
                                        {(this.state.isLoadingOffice365 && office365Hits === 0)
                                            ? localize("Laddar") + "..."
                                            : localizeFormat(
                                                "SEARCH_SHOW_X_O365_RESULTS_F",
                                                office365Hits + (office365HasMore ? "+" : "")
                                            )}
                                    </Label>
                                </Link>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default connect<IStateProps, IDispatchProps, any, Spintr.AppState>(
    (state) => {
        const aiAssistant: IAIAssistantProps = state.instance.get("aiAssistant") || { enabled: false, name: "" };

        return {
            displayHeaderSearch: state.ui.displayHeaderSearch,
            aiAssistant,
            mode: aiAssistant.enabled
                ? state.ui.headerSearchBarMode
                : SpintrTypes.HeaderSearchBarMode.Search,
            errorMessageTag: state.search.errorMessageTag,
            headers: state.search.currentHeaders,
            isLoading: state.search.isLoadingResults,
            lastParams: state.search.lastSuccessfulParams,
            isActive: state.search.isActive,
            office365Enabled:
                (state.instance.get("office365Enabled") as boolean) && state.profile.active.office365Connected,
            results: state.search.currentResults,
            //bansheeEnabled: state.instance.get("bansheeEnabled") as boolean,
            bansheeEnabled: true,
            useColorHeader: state.instance.get("theme")
                ? state.instance.get("theme").useColorHeader
                : state.instance.get("useColorHeader"),
            instanceColor: state.instance.get("theme")
                ? state.instance.get("theme").primaryColor
                : state.instance.get("color"),
            isAdmin: state.profile.active.isAdmin,
            isBetaTester: state.profile.active.settings.isBetaTester,
            currentUserName: state.profile.active.name,
            activeSubjects: state.assistant.activeSubjects,
        };
    },
    {
        clearSearchResults,
        executeSearchAction,
        setDisplayHeaderSearch,
        setHeaderSearchBarMode,
        setActiveSubjects,
        setActiveSubjectDate,
        setOverlayId
    }
)(withRouter(SpintrHeaderSearch));
