import React, { Component, ReactNode } from "react";
import { Helmet } from 'react-helmet';
import { connect, MapStateToProps } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { ConversationsPanel } from "src/chat/components/ConversationsPanel";
import { localize } from "src/l10n";
import { VisageSidebarDrawerId, VisageSidebarMode } from "src/sidebar";
import { setSidebarDrawerId, setSidebarMode } from "src/sidebar/actions";
import { SpintrTypes } from "src/typings";
import {
    queryConversations
} from "../../redux";
import { ConversationView } from "../ConversationView";
import { NewConversationView } from "../NewConversationView";
import "./MessagesRootView.scss";

interface IStateProps {
    hasLoadedConversations?: boolean;
    isLoading?: boolean;
    isMobile?: boolean;
    topConversationId?: number;
    dispatch?: any;
}

type IProps = {

}

type Props = IStateProps & RouteComponentProps & IProps;

class MessagesRootView extends Component<Props> {
    public componentDidMount(): void {
        if (!this.props.isMobile) {
            this.props.dispatch(setSidebarMode(VisageSidebarMode.drawer));
            this.props.dispatch(setSidebarDrawerId(VisageSidebarDrawerId.messages));
        }

        if (this.props.hasLoadedConversations) {
            return;
        }

        this.props.dispatch(queryConversations({
            fetchType: SpintrTypes.ConversationsFetchType.Users,
            skip: 0,
            take: 20,
        }));
    }

    public render(): ReactNode {
        // if (!this.props.hasLoadedConversations || this.props.isLoading) {
        //     return <Loader />;
        // }

        const isOnList = this.props.location.pathname.indexOf("/", 1) === -1;

        return (
            <div id="MessagesRootView">
                <Helmet>
                    <title>{localize("Meddelanden")}</title>
                </Helmet>
                {
                    this.props.isMobile && (
                        <div className="conversation-panel">
                            <ConversationsPanel calculateScrollHeight fullscreen />
                        </div>
                    )
                }
                <div className={
                    "conversation-main"
                    + (isOnList ? "" : " active")
                }>
                    <Switch>
                        <Route
                            component={NewConversationView}
                            exact={true}
                            path="/messages/new"
                        />
                        <Route
                            component={ConversationView}
                            path="/messages/:conversationId"
                        />
                        {this.renderRedirect()}
                    </Switch>
                </div>
            </div>
        );
    }

    protected renderRedirect(): ReactNode {
        if (this.props.isMobile) {
            return null;
        }

        const isOnRoot = this.props.location.pathname.indexOf("/", 1) === -1;
        if (!isOnRoot) {
            return null;
        }
        
        if (!!this.props.topConversationId && isNaN(this.props.topConversationId)) {
            return (
                <Redirect 
                    to={"/messages/" + this.props.topConversationId}
                />
            )
        }
  
        return null;
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IProps, Spintr.AppState> =
    (state) => ({
        hasLoadedConversations: state.chat.conversations.hasFetched,

        isLoading: state.chat.conversations.isLoading,
        isMobile: state.ui.viewMode <= SpintrTypes.ViewMode.PhoneLandscape,

        topConversationId: state.chat.conversations.items
            .filter((c) => c.type !== 3)
            .slice(0, 1)
            .map((c) => c.id)[0],

    });

/**
 * A view that handles sub-routes for the messaging system
 */
const ConnectedMessagesRootView = connect(
    mapStateToProps,
)(MessagesRootView)

export default ConnectedMessagesRootView;
