import React, { useEffect, useMemo, useRef } from 'react';
import "./MessageRead.scss";
import { Label, SpintrUser, UnstyledButton } from 'src/ui';
import { ReactSVG } from 'react-svg';
import { localize } from 'src/l10n';
import { IConversationReadData } from 'src/chat';

interface IProps {
    currentUserId: number;
    conversation: Spintr.IConversation;
    message: Spintr.IChatMessage;
    readData: IConversationReadData;
}

const MessageRead = (props: IProps) => {
    const users: Spintr.IConversationParticipant[] = useMemo(() => {
        const result: any[] = [];

        if (!props.conversation) {
            return result;
        }
    
        for (const [key, value] of Object.entries(props.conversation.lastRead)) {
            if (Number(key) === props.currentUserId) {
                continue;
            }

            if (value === props.message.id) {
                const participant = props.conversation?.participants.find(x => x.id.toString() === key);
    
                if (participant) {
                    result.push(participant);
                }
            }
        }
    
        return result;
    }, [props.conversation]);

    if (users.length === 0 &&
        props.readData?.lastMessageId !== props.message.id &&
        props.readData?.readByAllMessageId !== props.message.id) {
        return null;
    }

    return (
        <div className="MessageRead">
            <div className="button">
                {users.length > 0 && (
                    <div className="users">
                        {users.slice(0, 4).map((x) => {
                            return (
                                <SpintrUser
                                    key={x.id}
                                    name={x.name}
                                    imageUrl={x.imageUrl}
                                    personalName
                                    hideText
                                    size={16}
                                />
                            )
                        })}
                        {users.length > 5 && (
                            <div className="more">
                                <Label size="body-3" color="grey" weight="medium">{"+" + (users.length - 5)}</Label>
                            </div>
                        )}
                    </div>
                )}
                {(props.readData?.readByAllMessageId === props.message.id || props.readData?.lastMessageId === props.message.id) && (
                    <div className="ticks" title={localize(props.readData?.readByAllMessageId === props.message.id ? "READ_BY_ALL" : "Skickat")}>
                        <ReactSVG src={props.readData?.readByAllMessageId === props.message.id ? "/images/read-tick-2.svg" : "/images/read-tick-1.svg"} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default MessageRead;
