import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapDispatchToProps, MapStateToProps, connect } from 'react-redux';
import { BoundQueryMessagesHandler, IConversationReadData, IMessageUIData, MarkAsReadHandler, QueryMessagesHandler, markAsRead, queryMessages } from 'src/chat/redux';
import { MessageFetchType } from 'src/chat/message-types';
import Message from '../Message/Message';
import SpintrLoader from 'src/ui/components/Loader';
import { IActiveUserProfile } from 'src/profile/reducer';
import { createSelector } from 'reselect';
import { IApplicationState } from 'src/spintr/reducer';
import IsTyping from '../IsTyping/IsTyping';
import "./Messages.scss";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { setTimeout } from 'timers';

interface IDispatchProps {
    queryMessages: QueryMessagesHandler | BoundQueryMessagesHandler;
    markAsRead: MarkAsReadHandler;
}

interface IOwnProps {
    conversationId: number;
    compact?: boolean;
    scrollToMessageId?: number;
    innerRef?: any;
}

interface IStateProps {
    currentUser: IActiveUserProfile;
    conversation: Spintr.IConversation;
    messages?: Spintr.IChatMessage[];
    isLoading?: boolean;
    hasMore?: boolean;
    isSmallViewMode?: boolean;
}

type Props = IDispatchProps & IOwnProps & IStateProps;

const take = 20;

interface IState {
    scrollTo?: {
        id: number,
        fetchUntilFound?: boolean
    },
    isInitialized: boolean;
    isLoading: boolean;
}

const Messages = (props: Props) => {
    const virtuosoRef = useRef<VirtuosoHandle>(null);
    const scrollerRef = useRef<HTMLDivElement>(null);
    const messagesWithDates = useRef<number[]>([]);

    const [state, setState] = useState<IState>({
        isInitialized: false,
        scrollTo: undefined,
        isLoading: false
    });

    const ui: { [id: number]: IMessageUIData } = useMemo(() => {
        const result: { [id: number]: IMessageUIData } = {};

        for (let i = 0; i < props.messages.length; i++) {
            const prev = props.messages[i - 1];
            const m = props.messages[i];
            const displayDate = messagesWithDates.current.includes(m.id) || !prev || m.date.getTime() - prev.date.getTime() > 3600000;

            if (displayDate) {
                messagesWithDates.current.push(m.id);
            }
            
            result[m.id] = {
                displayDate
            }
        }
        
        for (let i = 0; i < props.messages.length; i++) {
            const prev = props.messages[i - 1];
            const m = props.messages[i];
            const next = props.messages[i + 1];

            const isTop = result[m.id].displayDate || !prev || prev.user.id !== m.user.id;
            
            result[m.id] = {
                ...result[m.id],
                isTop,
                isBottom: !next || result[next.id].displayDate || next.user.id !== m.user.id
            }
        }

        messagesWithDates.current = messagesWithDates.current.filter((value, index, array) => array.indexOf(value) === index);

        return result;
    }, [props.messages]);

    const readData: IConversationReadData = useMemo(() => {
        let readByAllMessageId = 0;
        let lastMessageId = 0;

        const readByAllId = Object.values(props.conversation?.lastRead || {}).map((id) => id).sort((a, b) => a < b ? -1 : 1)[0];

        for (let m of props.messages) {
            if (m.user.id !== props.currentUser.id) {
                continue;
            }

            if (m.id > 0 && m.id <= readByAllId) {
                readByAllMessageId = m.id;
            }

            lastMessageId = m.id;
        }

        return { readByAllMessageId, lastMessageId };
    }, [props.messages, props.conversation]);

    const fetch = useCallback(() => {
        if (props.isLoading || !props.hasMore || !state.isInitialized) {
            return;
        }

        const promise = (props.queryMessages as BoundQueryMessagesHandler)({
            conversationId: props.conversationId,
            fetchType: MessageFetchType.Messages,
            take: take,
            maxId: props.messages[0].id,
            flatMode: true
        });

        setState((s) => ({
            ...s,
            isLoading: true
        }));

        promise.then(() => {
            setState((s) => ({
                ...s,
                isLoading: false
            }));
        }).catch(() => {});
    }, [props.messages, props.isLoading, props.hasMore, state.scrollTo, state.isInitialized]);

    const initialFetch = useCallback(() => {
        const promise = (props.queryMessages as BoundQueryMessagesHandler)({
            conversationId: props.conversationId,
            fetchType: MessageFetchType.Messages,
            take: take,
            maxId: 0,
            flatMode: true
        });

        promise.then(() => {
            setState((s) => ({
                ...s,
                isInitialized: true
            }));
        }).catch(() => {});
    }, []);

    const scrollToId = useCallback((id: number) => {
        if (!virtuosoRef.current) {
            return;
        }

        const message = props.messages.find(x => x.id === id);

        if (!message) {
            return;
        }

        const index = props.messages.indexOf(message);

        virtuosoRef.current.scrollToIndex({
            index,
            align: "start",
            behavior: "smooth"
        });

        setTimeout(() => {
            virtuosoRef.current.scrollToIndex({
                index,
                align: "start",
                behavior: "smooth"
            });
        }, 500);
    }, [props.messages, props.hasMore, state.scrollTo, virtuosoRef.current]);

    const markAsRead = useCallback(() => {
        if (!props.conversation || !props.conversation.unread) {
            return;
        }

        props.markAsRead(props.conversation.id, props.conversation.unread);
    }, [props.conversation]);

    const followOutput = useCallback((isAtBottom: boolean) => {
        if (state.isLoading) {
            return false;
        }

        const isOwnMessage = props.messages[props.messages.length - 1].user.id === props.currentUser.id;

        if (isOwnMessage) {
            return isAtBottom ? "smooth" : "auto";
        } else {
            return isAtBottom ? "smooth" : false;
        }
    }, [props.messages, state.isLoading]);

    const handleScrollerRef = useCallback((ref) => {
        scrollerRef.current = ref;
    }, []);

    useEffect(() => {
        initialFetch();
    }, []);

    useEffect(() => {
        markAsRead();
    }, [props.messages]);

    useEffect(() => {
        if (virtuosoRef.current && !state.isInitialized) {
            virtuosoRef.current.scrollToIndex(props.messages.length - 1);
        }
    }, [virtuosoRef.current]);

    useEffect(() => {
        if (!state.scrollTo || !virtuosoRef.current) {
            return;
        }

        const message = props.messages.find(x => x.id === state.scrollTo.id);

        if (!message && state.scrollTo.fetchUntilFound) {
            return fetch();
        }

        scrollToId(state.scrollTo.id);

        setState((s) => ({
            ...s,
            scrollTo: undefined
        }));
    }, [state.scrollTo, props.messages, props.hasMore, virtuosoRef.current]);

    useEffect(() => {
        if (props.innerRef) {
            props.innerRef.current = {
                scrollTo: (id: number) => {
                    setState((s) => ({
                        ...s,
                        scrollTo: {
                            id,
                            align: "start",
                            fetchUntilFound: true
                        }
                    }));
                }
            }
        }
    }, []);

    return (
        <Virtuoso
            ref={virtuosoRef}
            scrollerRef={handleScrollerRef}
            style={{ flex: 1 }}
            increaseViewportBy={{ top: 0, bottom: 500 }}
            overscan={500}
            alignToBottom
            followOutput={followOutput}
            itemContent={(index: number, message: Spintr.IChatMessage, context: any) => {
                return (
                    <Message
                        data-id={message.id}
                        isSmallViewMode={props.isSmallViewMode}
                        currentUserId={props.currentUser.id}
                        currentUserName={props.currentUser.name}
                        conversation={props.conversation}
                        compact={props.compact}
                        message={message}
                        uiData={ui[message.id]}
                        readData={readData}
                        scrollToMessage={(id: number) => {
                            setState((s) => ({
                                ...s,
                                scrollTo: {
                                    id,
                                    fetchUntilFound: true
                                }
                            }));
                        }} />
                )
            }}
            data={props.messages}
            firstItemIndex={1000000 - props.messages.length}
            startReached={fetch}
            components={{
                Header: () => props.hasMore ? <SpintrLoader /> : null,
                Footer: () => <IsTyping
                    conversationId={props.conversationId}
                    onVisible={() => {
                        setTimeout(() => {
                            if ((scrollerRef.current.scrollHeight - scrollerRef.current.scrollTop) < 500) {
                                virtuosoRef.current.scrollTo({top: 1000000})
                            }
                        }, 100);
                    }} />
            }}
        />
    )
}

const messagesSelector = createSelector(
    [
        (state: IApplicationState) => state.chat.messages.items,
        (_, ownProps: IOwnProps) => ownProps.conversationId
    ],
    (messages, conversationId) => messages.filter(x => x.conversationId == conversationId)
)

const conversationSelector = createSelector(
    [
        (state: IApplicationState) => state.chat.conversations.items,
        (_, ownProps: IOwnProps) => ownProps.conversationId
    ],
    (conversations, conversationId) => conversations.find(x => x.id == conversationId)
)

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state, ownProps) => ({
        currentUser: state.profile.active,
        conversation: conversationSelector(state, ownProps),
        messages: messagesSelector(state, ownProps),
        isLoading: state.chat.messages.isLoading[ownProps.conversationId],
        hasMore: state.chat.messages.hasMore[ownProps.conversationId],
        isSmallViewMode: state.ui.isSmallViewMode,
    });

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = {
    queryMessages,
    markAsRead,
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
