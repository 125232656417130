import React, { ReactElement } from "react";
import { PlannerItemDetailProps } from "./PlannerItemDetail.types";
import { CaptionBody, CaptionBolder, SmallBody } from "src/components/Text";
import { localize } from "src/l10n";
import moment from "moment";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { Conditional } from "src/components/Conditional";
import { getPlannerItemTypeText, getPlannerResourceTypeText } from "../utils";
import ExpandableFormField from "src/ui/components/ExpandableFormField/ExpandableFormField";
import { SpintrUser } from "src/ui";
import { SpintrTypes } from "src/typings";
import PlannerReleaseDetails from "./PlannerReleaseDetails";

const dateFormat = "YYYY-MM-DD";
const dateTimeFormat = "YYYY-MM-DD HH:mm";

function renderFollowUpAndEvaluation(item: Spintr.PlannerItem): ReactElement {
    const goalsIsValid = !!item.goals && item.goals.length > 0;
    const followUpByIsValid = typeof item.followUpBy !== "undefined";
    const evaluationMethodIsValid = !!item.evaluationMethod && item.evaluationMethod.length > 0;
    const resultsIsValid = !!item.results && item.results.length > 0;

    if (!goalsIsValid && !followUpByIsValid && !evaluationMethodIsValid && !resultsIsValid) {
        return null;
    }
    return (
        <ExpandableFormField title={localize("PLANNER_FOLLOWUP_EVALUATION")}>
            <Conditional condition={goalsIsValid}>
                <div className="PlannerItemDetail-advancedField">
                    <CaptionBolder color="contentDark">
                        {localize("PLANNER_MEASURABLE_GOALS")}
                    </CaptionBolder>
                    <div className="PlannerItemDetail-advancedField-content">
                        <SmallBody color="contentLight" weight="regular">
                            {item.goals}
                        </SmallBody>
                    </div>
                </div>
            </Conditional>
            
            <Conditional condition={followUpByIsValid}>
                <div className="PlannerItemDetail-advancedField">
                    <CaptionBolder color="contentDark">
                        {localize("PLANNER_FOLLOWUP_DATE")}
                    </CaptionBolder>
                    <div className="PlannerItemDetail-advancedField-content">
                        <SmallBody color="contentLight" weight="regular">
                            {moment(item.followUpBy).format(dateFormat)}
                        </SmallBody>
                    </div>
                </div>
            </Conditional>
            
            <Conditional condition={goalsIsValid}>
                <div className="PlannerItemDetail-advancedField">
                    <CaptionBolder color="contentDark">
                        {localize("PLANNER_EVALUATION_METHOD")}
                    </CaptionBolder>
                    <div className="PlannerItemDetail-advancedField-content">
                        <SmallBody color="contentLight" weight="regular">
                            {item.evaluationMethod}
                        </SmallBody>
                    </div>
                </div>
            </Conditional>

            
            <Conditional condition={goalsIsValid}>
                <div className="PlannerItemDetail-advancedField">
                    <CaptionBolder color="contentDark">
                        {localize("PLANNER_MEASURABLE_RESULTS")}
                    </CaptionBolder>
                    <div className="PlannerItemDetail-advancedField-content">
                        <SmallBody color="contentLight" weight="regular">
                            {item.results}
                        </SmallBody>
                    </div>
                </div>
            </Conditional>
        </ExpandableFormField>
    );
}

function renderResourcesAndBudget(item: Spintr.PlannerItem): ReactElement {
    const resourcesValid = item.requiredResources && item.requiredResources.length > 0;
    const budgetValid = item.budget && item.budget.length > 0;
    const budgetApprovedValid = typeof item.budgetApproved !== undefined;
    const costCenterValid = item.costCenter && item.costCenter.length > 0;

    if (!resourcesValid && !budgetValid && !budgetApprovedValid && !costCenterValid) {
        return null;
    }
    return (
        <ExpandableFormField title={localize("PLANNER_RESOURCES_BUDGET")}>
            <Conditional condition={resourcesValid}>
                <div className="PlannerItemDetail-advancedField">
                    <CaptionBolder color="contentDark">
                        {localize("PLANNER_RESOURCES")}
                    </CaptionBolder>
                    <div className="PlannerItemDetail-advancedField-content">
                        <SmallBody color="contentLight" weight="regular">
                            {item.requiredResources
                                .map(getPlannerResourceTypeText)
                                .filter((text) => !!text)
                                .join(", ")}
                        </SmallBody>
                    </div>
                </div>
            </Conditional>
            
            <Conditional condition={budgetValid}>
                <div className="PlannerItemDetail-advancedField">
                    <CaptionBolder color="contentDark">
                        {localize("PLANNER_BUDGET")}
                    </CaptionBolder>
                    <div className="PlannerItemDetail-advancedField-content">
                        <SmallBody color="contentLight" weight="regular">
                            {item.budget}
                        </SmallBody>
                    </div>
                </div>
            </Conditional>
            
            <Conditional condition={budgetApprovedValid}>
                <div className="PlannerItemDetail-advancedField">
                    <CaptionBolder color="contentDark">
                        {localize("PLANNER_BUDGET_APPROVED")}
                    </CaptionBolder>
                    <div className="PlannerItemDetail-advancedField-content">
                        <SmallBody color="contentLight" weight="regular">
                            {localize(item.budgetApproved ? "Ja" : "Nej")}
                        </SmallBody>
                    </div>
                </div>
            </Conditional>

            
            <Conditional condition={costCenterValid}>
                <div className="PlannerItemDetail-advancedField">
                    <CaptionBolder color="contentDark">
                        {localize("PLANNER_COST_CENTER")}
                    </CaptionBolder>
                    <div className="PlannerItemDetail-advancedField-content">
                        <SmallBody color="contentLight" weight="regular">
                            {item.costCenter}
                        </SmallBody>
                    </div>
                </div>
            </Conditional>
        </ExpandableFormField>
    );
}

function renderApproval(item: Spintr.PlannerItem): ReactElement {
    const approvedByValid = typeof item.approvedBy === "object" && item.approvedBy.name.length > 0;
    const approvedOnValid = typeof item.approvedOn !== "undefined";
    const approvalCommentValid = item.approvalComment && item.approvalComment.length > 0;

    if (!approvedByValid && !approvedOnValid && !approvalCommentValid) {
        return null;
    }
    return (
        <ExpandableFormField title={localize("PLANNER_APPROVAL")}>
            <Conditional condition={approvedByValid}>
                <div className="PlannerItemDetail-advancedField">
                    <CaptionBolder color="contentDark">
                        {localize("PLANNER_APPROVAL_BY")}
                    </CaptionBolder>
                    <div className="PlannerItemDetail-advancedField-content">
                        <SpintrUser
                            name={item.approvedBy.name}
                            personalName={true}
                            imageUrl={item.approvedBy.imageUrl} />
                    </div>
                </div>
            </Conditional>
            
            <Conditional condition={approvedOnValid}>
                <div className="PlannerItemDetail-advancedField">
                    <CaptionBolder color="contentDark">
                        {localize("PLANNER_APPROVAL_DATE")}
                    </CaptionBolder>
                    <div className="PlannerItemDetail-advancedField-content">
                        <SmallBody color="contentLight" weight="regular">
                            {moment(item.approvedOn).format(dateFormat)}
                        </SmallBody>
                    </div>
                </div>
            </Conditional>
            
            <Conditional condition={approvalCommentValid}>
                <div className="PlannerItemDetail-advancedField">
                    <CaptionBolder color="contentDark">
                        {localize("PLANNER_APPROVAL_COMMENT")}
                    </CaptionBolder>
                    <div className="PlannerItemDetail-advancedField-content">
                        <SmallBody color="contentLight" weight="regular">
                            {item.approvalComment}
                        </SmallBody>
                    </div>
                </div>
            </Conditional>

        </ExpandableFormField>
    );
}

function renderTypeContent(item: Spintr.PlannerItem): ReactElement {
    switch (item.itemType) {
        case SpintrTypes.PlannerItemType.Release:
            return <PlannerReleaseDetails item={item} />;

        default:
            return null;
    }
}

function PlannerItemDetail(props: PlannerItemDetailProps): ReactElement {
    const { item } = props;

    return (
        <section className="PlannerItemDetail">
            <div className="PlannerItemDetail-basic">
                <div className="PlannerItemDetail-textBlock">
                    <SmallBody color="contentNormal" weight="regular">
                        {item.description}
                    </SmallBody>
                </div>

                <section className="PlannerItemDetail-infoBlock">
                    <SmallBody
                        className="PlannerItemDetail-infoBlock-heading"
                        color="contentDark"
                        weight="medium"
                    >
                        {localize("DETAILS")}
                    </SmallBody>
                    
                    <div className="PlannerItemDetail-infoBlock-content">
                        <div className="PlannerItemDetail-infoBlock-property">
                            <div className="PlannerItemDetail-infoBlock-property-label">
                                <Visage2Icon icon="tag" color="spintrGrey" size="medium" />
                                <CaptionBolder color="contentDark">
                                    {localize("Typ")}
                                </CaptionBolder>
                            </div>
                            <div className="PlannerItemDetail-infoBlock-property-value">
                                <CaptionBody color="contentNormal">
                                    {getPlannerItemTypeText(item.itemType)}
                                </CaptionBody>
                            </div>
                        </div>

                        <div className="PlannerItemDetail-infoBlock-property">
                            <div className="PlannerItemDetail-infoBlock-property-label">
                                <Visage2Icon icon="calendar-1" color="spintrGrey" size="medium" />
                                <CaptionBolder color="contentDark">
                                    {localize("Startdatum")}
                                </CaptionBolder>
                            </div>
                            <div className="PlannerItemDetail-infoBlock-property-value">
                                <CaptionBody color="contentNormal">
                                    {moment(item.startsAt).format(item.allDayEvent ? dateFormat : dateTimeFormat)}
                                </CaptionBody>
                            </div>
                        </div>

                        <div className="PlannerItemDetail-infoBlock-property">
                            <div className="PlannerItemDetail-infoBlock-property-label">
                                <Visage2Icon icon="calendar-1" color="spintrGrey" size="medium" />
                                <CaptionBolder color="contentDark">
                                    {localize("Slutdatum")}
                                </CaptionBolder>
                            </div>
                            <div className="PlannerItemDetail-infoBlock-property-value">
                                <CaptionBody color="contentNormal">
                                    {moment(item.endsAt).format(item.allDayEvent ? dateFormat : dateTimeFormat)}
                                </CaptionBody>
                            </div>
                        </div>

                        <Conditional condition={item.location}>
                            <div className="PlannerItemDetail-infoBlock-property">
                                <div className="PlannerItemDetail-infoBlock-property-label">
                                    <Visage2Icon icon="location" color="spintrGrey" size="medium" />
                                    <CaptionBolder color="contentDark">
                                        {localize("Plats")}
                                    </CaptionBolder>
                                </div>
                                <div className="PlannerItemDetail-infoBlock-property-value">
                                    <CaptionBody color="contentNormal">
                                        {item.location}
                                    </CaptionBody>
                                </div>
                            </div>
                        </Conditional>
                    </div>
                </section>

                {renderTypeContent(item)}
            </div>

            <section className="PlannerItemDetail-advanced">
                {renderFollowUpAndEvaluation(item)}
                {renderResourcesAndBudget(item)}
                {renderApproval(item)}
            </section>
        </section>
    );
}

export default PlannerItemDetail;
