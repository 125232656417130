import { Checkbox, DayOfWeek, Dropdown, IDropdownOption, Pivot, PivotItem, Stack, TextField } from "@fluentui/react";
import React, { FormEvent, FormEventHandler, ReactElement, useCallback, useMemo } from "react";
import { getTypeName, localize } from "src/l10n";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import { FormControl, FormSection, FormTokenizedObjectInput } from "src/ui/components/Forms";
import getDatePickerLanguageStrings from "src/utils/getDatePickerLanguageStrings";
import { PlannerItemFormProps } from "./PlannerItemForm.types";
import { SpintrTypes } from "src/typings";
import { PlannerItemErrorState, PlannerModel, UpdatePlannerModelHandler } from "../types";
import ITokenizedInputItem from "src/ui/components/Forms/ITokenizedInputItem";
import ExpandableFormField from "src/ui/components/ExpandableFormField/ExpandableFormField";
import PlannerCampaignForm from "./PlannerCampaignForm";
import PlannerCourseForm from "./PlannerCourseForm";
import PlannerDeliveryForm from "./PlannerDeliveryForm";
import PlannerEventForm from "./PlannerEventForm";
import PlannerInventoryForm from "./PlannerInventoryForm";
import PlannerMaintenanceForm from "./PlannerMaintenanceForm";
import PlannerReleaseForm from "./PlannerReleaseForm";
import { PlannerColorPicker } from "../PlannerColorPicker";
import { useSelector } from "react-redux";
import { Conditional } from "src/components/Conditional";

function getPlannerItemTypes(): IDropdownOption[] {
    return [{
        key: SpintrTypes.PlannerItemType.General,
        text: localize("PLANNER_TYPE_GENERAL"),
    }, {
        key: SpintrTypes.PlannerItemType.Campaign,
        text: localize("PLANNER_TYPE_CAMPAIGN"),
    }, {
        key: SpintrTypes.PlannerItemType.Course,
        text: localize("PLANNER_TYPE_COURSE"),
    }, {
        key: SpintrTypes.PlannerItemType.Delivery,
        text: localize("PLANNER_TYPE_DELIVERY"),
    }, {
        key: SpintrTypes.PlannerItemType.Event,
        text: localize("PLANNER_TYPE_EVENT"),
    }, {
        key: SpintrTypes.PlannerItemType.Inventory,
        text: localize("PLANNER_TYPE_INVENTORY"),
    }, {
        key: SpintrTypes.PlannerItemType.Maintenance,
        text: localize("PLANNER_TYPE_MAINTENANCE"),
    }, {
        key: SpintrTypes.PlannerItemType.Release,
        text: localize("PLANNER_TYPE_RELEASE"),
    }]
}

function getResourcesOptions(): IDropdownOption[] {
    return [{
        key: SpintrTypes.PlannerItemResourceType.Equipment,
        text: localize("PLANNER_RESOURCE_EQUIPMENT"),
    }, {
        key: SpintrTypes.PlannerItemResourceType.Material,
        text: localize("PLANNER_RESOURCE_MATERIAL"),
    }, {
        key: SpintrTypes.PlannerItemResourceType.Personnel,
        text: localize("PLANNER_RESOURCE_PERSONNEL"),
    }]
}

function renderTypeSpecificContent(props: PlannerItemFormProps): ReactElement {
    const type = props.model.itemType;

    switch (type) {
        case SpintrTypes.PlannerItemType.Campaign:
            return <PlannerCampaignForm
                errors={props.errors as PlannerItemErrorState<Spintr.PlannerCampaign>}
                model={props.model as PlannerModel<Spintr.PlannerCampaign>}
                onModelUpdate={props.onModelUpdate as UpdatePlannerModelHandler<Spintr.PlannerCampaign>} />

        case SpintrTypes.PlannerItemType.Course:
            return <PlannerCourseForm
                errors={props.errors as PlannerItemErrorState<Spintr.PlannerCourse>}
                model={props.model as PlannerModel<Spintr.PlannerCourse>}
                onModelUpdate={props.onModelUpdate as UpdatePlannerModelHandler<Spintr.PlannerCourse>} />

        case SpintrTypes.PlannerItemType.Delivery:
            return <PlannerDeliveryForm
                errors={props.errors as PlannerItemErrorState<Spintr.PlannerDelivery>}
                model={props.model as PlannerModel<Spintr.PlannerDelivery>}
                onModelUpdate={props.onModelUpdate as UpdatePlannerModelHandler<Spintr.PlannerDelivery>} />

        case SpintrTypes.PlannerItemType.Event:
            return <PlannerEventForm
                errors={props.errors as PlannerItemErrorState<Spintr.PlannerEvent>}
                model={props.model as PlannerModel<Spintr.PlannerEvent>}
                onModelUpdate={props.onModelUpdate as UpdatePlannerModelHandler<Spintr.PlannerEvent>} />

        case SpintrTypes.PlannerItemType.Inventory:
            return <PlannerInventoryForm
                errors={props.errors as PlannerItemErrorState<Spintr.PlannerInventory>}
                model={props.model as PlannerModel<Spintr.PlannerInventory>}
                onModelUpdate={props.onModelUpdate as UpdatePlannerModelHandler<Spintr.PlannerInventory>} />

        case SpintrTypes.PlannerItemType.Maintenance:
            return <PlannerMaintenanceForm
                errors={props.errors as PlannerItemErrorState<Spintr.PlannerMaintenance>}
                model={props.model as PlannerModel<Spintr.PlannerMaintenance>}
                onModelUpdate={props.onModelUpdate as UpdatePlannerModelHandler<Spintr.PlannerMaintenance>} />

        case SpintrTypes.PlannerItemType.Release:
            return <PlannerReleaseForm
                errors={props.errors as PlannerItemErrorState<Spintr.PlannerRelease>}
                model={props.model as PlannerModel<Spintr.PlannerRelease>}
                onModelUpdate={props.onModelUpdate as UpdatePlannerModelHandler<Spintr.PlannerRelease>} />

        default: return null;
    }
}

function PlannerItemForm(props: PlannerItemFormProps): ReactElement {
    const { errors, model, onModelUpdate } = props;

    const typeOptions = useMemo(getPlannerItemTypes, []);
    const resourceOptions = useMemo(getResourcesOptions, []);

    const enableCompanyLevel = useSelector<Spintr.AppState, boolean>(
        (appState) => appState.instance.get("enableCompanyLevel") as boolean,
    );

    const hasElevatedPermissions = useSelector<Spintr.AppState, boolean>(
        (appState) => appState.profile.active.roles.some(
            (role) => role === "administrators" || role === "editor",
        ),
    );

    const onTextFieldChanged = useCallback<FormEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (event) => {
            if (!event?.target) {
                return;
            }

            const { name, value } = (event.target as HTMLInputElement | HTMLTextAreaElement);

            onModelUpdate((prevModel) => Object.assign(
                { ...prevModel },
                { [name]: value },
            ) as PlannerModel);
        },
        [onModelUpdate],
    );

    const onCheckBoxChanged = useCallback(
        (event: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
            if (!event?.target) {
                return;
            }

            const { name } = (event.target as HTMLInputElement);

            onModelUpdate((prevModel) => Object.assign(
                { ...prevModel },
                { [name]: !!checked },
            ) as PlannerModel);
        },
        [onModelUpdate],
    );

    const onIemTypeChanged = useCallback((_: unknown, item: IDropdownOption) => onModelUpdate((prevModel) => ({
        ...prevModel,
        itemType: item.key as number,
    })), [onModelUpdate]);

    const onStartDateChanged = useCallback((date: Date) => onModelUpdate((prevModel) => ({
        ...prevModel,
        startsAt: date,
    })), [onModelUpdate]);

    const onEndDateChanged = useCallback((date: Date) => onModelUpdate((prevModel) => ({
        ...prevModel,
        endsAt: date,
    })), [onModelUpdate]);

    const onFollowUpDateChanged = useCallback((date: Date) => onModelUpdate((prevModel) => ({
        ...prevModel,
        followUpBy: date,
    })), [onModelUpdate]);

    const onApprovalDateChanged = useCallback((date: Date) => onModelUpdate((prevModel) => ({
        ...prevModel,
        approvedOn: date,
    })), [onModelUpdate]);

    const onUsersChanged = useCallback(
        (items : ITokenizedInputItem[]) => onModelUpdate((prevModel) => ({
            ...prevModel,
            assignees: items.map((item) => ({
                id: typeof item.key === "string" ? parseInt(item.key, 0) : item.key,
                name: item.name,
                imageUrl: item.imageUrl,
                subText: item.subText,
            })),
        })),
        [onModelUpdate],
    );

    const onApprovedByChanged = useCallback(
        (items : ITokenizedInputItem[]) => onModelUpdate((prevModel) => ({
            ...prevModel,
            approvedBy: items.length === 0 ? undefined : items
                .map((item) => ({
                    id: typeof item.key === "string" ? parseInt(item.key, 0) : item.key,
                    name: item.name,
                    imageUrl: item.imageUrl,
                    subText: item.subText,
                }))[0],
        })),
        [onModelUpdate],
    );

    const onTargetsChanged = useCallback(
        (items : ITokenizedInputItem[]) => onModelUpdate((prevModel) => ({
            ...prevModel,
            targetedTo: items.map((item) => ({
                id: typeof item.key === "string" ? parseInt(item.key, 0) : item.key,
                name: item.name,
                imageUrl: item.imageUrl,
                subText: item.subText,
            })),
        })),
        [onModelUpdate],
    );

    const onResourcesChanged = useCallback((_: unknown, item: IDropdownOption) => onModelUpdate((prevModel) => {
        const resource = item.key as Spintr.PlannerItemResourceType;
        
        let requiredResources = [...prevModel.requiredResources || []];
        if (requiredResources.includes(resource) && !item.selected) {
            requiredResources = requiredResources.filter((r) => r !== resource);
        } else if (!requiredResources.includes(resource) && item.selected) {
            requiredResources = [...requiredResources, resource];
        }

        return {
            ...prevModel,
            requiredResources: requiredResources,
        };
    }), [onModelUpdate]);

    const onColorChange = useCallback((color: string) => onModelUpdate((prevModel) => ({
        ...prevModel,
        color,
    })), [onModelUpdate]);

    const onPersonalChanged = useCallback((_: unknown, checked?: boolean) => onModelUpdate((prevModel) => ({
        ...prevModel,
        plannerOwner: checked ? { id: props.userId, name: "", type: 1  } : undefined,
    })), [props.userId, onModelUpdate]);

    const onBoardOwnerChanged = useCallback(
        (items : ITokenizedInputItem[]) => onModelUpdate((prevModel) => ({
            ...prevModel,
            plannerOwner: items.map<Spintr.PlannerOwner>((item) => ({
                id: typeof item.key === "string" ? parseInt(item.key, 0) : item.key,
                name: item.name,
                imageUrl: item.imageUrl,
                type: item.type,
            }))[items.length - 1],
        })),
        [onModelUpdate],
    );

    return (
        <div className="PlannerItemForm">
            <Pivot>
                <PivotItem headerText={localize("Allmant")}>
                    
                    <FormSection>

                        <FormControl>
                            <TextField
                                aria-required={true}
                                className="textField"
                                label={localize("Namn")}
                                name="name"
                                onChange={onTextFieldChanged}
                                required={true}
                                validateOnFocusIn={true}
                                validateOnFocusOut={true}
                                errorMessage={errors.name}
                                value={model.name || ""}
                            />
                        </FormControl>

                        {/* Move the tickbox outside and do some conditional crap for groups */}
                        <Conditional condition={hasElevatedPermissions}>
                            <FormControl label={localize("Kategori")}>
                                <Checkbox
                                    checked={model.plannerOwner?.id === props.userId}
                                    label={localize("Personlig")}
                                    onChange={onPersonalChanged} />

                                <Conditional condition={model.plannerOwner?.id !== props.userId}>
                                    <FormTokenizedObjectInput
                                        items={(!model.plannerOwner || model.plannerOwner.id === 0) ? [] : [{
                                            key: model.plannerOwner.id,
                                            name: model.plannerOwner.name,
                                            imageUrl: model.plannerOwner.imageUrl,
                                            subText: getTypeName(model.plannerOwner.type, {
                                                case: "indefinite",
                                                form: "singular",
                                            }),
                                        }]}
                                        onChange={onBoardOwnerChanged}
                                        showAllWhenEmpty={true}
                                        types={[
                                            SpintrTypes.UberType.Department,
                                            SpintrTypes.UberType.Office,
                                            // TODO: Add group here to enable groups
                                            ...enableCompanyLevel
                                                ? [SpintrTypes.UberType.Company]
                                                : [],
                                        ]} />
                                    </Conditional>

                            </FormControl>
                        </Conditional>

                        <FormControl label={localize("Farg")}>
                            <PlannerColorPicker
                                onChange={onColorChange}
                                selectedColor={model.color} />
                        </FormControl>

                        <FormControl>
                            <Dropdown
                                label={localize("ValjTyp")}
                                multiSelect={false}
                                onChange={onIemTypeChanged}
                                options={typeOptions}
                                selectedKey={model.itemType} />
                        </FormControl>

                        <FormControl>
                            <TextField
                                aria-required={true}
                                className="textField"
                                label={localize("Beskrivning")}
                                name="description"
                                multiline={true}
                                onChange={onTextFieldChanged}
                                required={true}
                                validateOnFocusIn={true}
                                validateOnFocusOut={true}
                                errorMessage={errors.description}
                                value={model.description || ""}
                            />
                        </FormControl>

                        <FormControl>
                            <FormTokenizedObjectInput
                                items={model.assignees.map((assignee) => ({
                                    key: assignee.id,
                                    name: assignee.name,
                                    imageUrl: assignee.imageUrl,
                                    subText: assignee.subText,
                                }))}
                                label={localize("Ansvarig")}
                                onChange={onUsersChanged}
                                types={[1]} />
                        </FormControl>

                        <Conditional condition={model.plannerOwner?.id !== props.userId}>
                            <FormControl>
                                <FormTokenizedObjectInput
                                    items={model.targetedTo.map((target) => ({
                                        key: target.id,
                                        name: target.name,
                                        imageUrl: target.imageUrl,
                                        subText: target.subText,
                                    }))}
                                    label={localize("SynligFor")}
                                    onChange={onTargetsChanged}
                                    showAllWhenEmpty={true}
                                    types={[
                                        SpintrTypes.UberType.Department,
                                        SpintrTypes.UberType.Office,
                                        SpintrTypes.UberType.Role,
                                        SpintrTypes.UberType.TargetGroup,
                                        ...enableCompanyLevel
                                            ? [SpintrTypes.UberType.Company]
                                            : [],
                                    ]} />
                            </FormControl>
                        </Conditional>

                        <FormControl>

                            <Stack horizontal={true} horizontalAlign="space-between">
                                <SpintrDatePicker
                                    label={localize("Fran")}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={getDatePickerLanguageStrings()}
                                    formatDate={undefined}
                                    placeholder={localize("ValjDatum")}
                                    ariaLabel={localize("ValjDatum")}
                                    value={model.startsAt}
                                    maxDate={undefined}
                                    isRequired={true}
                                    aria-required={true}
                                    hideTimeSelect={model.allDayEvent}
                                    onChangeHandler={onStartDateChanged} />

                                <SpintrDatePicker
                                    label={localize("Till")}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={getDatePickerLanguageStrings()}
                                    formatDate={undefined}
                                    placeholder={localize("ValjDatum")}
                                    ariaLabel={localize("ValjDatum")}
                                    value={model.endsAt}
                                    maxDate={undefined}
                                    isRequired={true}
                                    aria-required={true}
                                    popperPlacement="bottom-end"
                                    hideTimeSelect={model.allDayEvent}
                                    onChangeHandler={onEndDateChanged} />
                            </Stack>

                            <Checkbox
                                checked={model.allDayEvent ?? false}
                                name="allDayEvent"
                                onChange={onCheckBoxChanged}
                                label={localize("Heldag")} />

                        </FormControl>

                        <FormControl>
                            <TextField
                                aria-required={true}
                                className="textField"
                                label={localize("Plats")}
                                name="location"
                                onChange={onTextFieldChanged}
                                required={false}
                                validateOnFocusIn={true}
                                validateOnFocusOut={true}
                                errorMessage={undefined}
                                value={model.location || ""} />
                        </FormControl>

                    </FormSection>

                    {renderTypeSpecificContent(props)}

                </PivotItem>

                <PivotItem headerText={localize("Avancerat")}>
                    
                    <ExpandableFormField title={localize("PLANNER_FOLLOWUP_EVALUATION")}>

                        <FormControl>

                            <TextField
                                aria-required={false}
                                className="textField"
                                label={localize("PLANNER_MEASURABLE_GOALS")}
                                multiline={true}
                                name="goals"
                                onChange={onTextFieldChanged}
                                required={false}
                                validateOnFocusIn={true}
                                validateOnFocusOut={true}
                                errorMessage={errors.goals}
                                value={model.goals || ""}
                            />

                        </FormControl>

                        <FormControl>
                            
                            <SpintrDatePicker
                                label={localize("PLANNER_FOLLOWUP_DATE")}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDatePickerLanguageStrings()}
                                formatDate={undefined}
                                placeholder={localize("ValjDatum")}
                                ariaLabel={localize("ValjDatum")}
                                value={model.followUpBy}
                                maxDate={undefined}
                                isRequired={false}
                                isClearable={true}
                                aria-required={false}
                                onChangeHandler={onFollowUpDateChanged} />

                        </FormControl>

                        <FormControl>

                            <TextField
                                aria-required={false}
                                className="textField"
                                label={localize("PLANNER_EVALUATION_METHOD")}
                                name="evaluationMethod"
                                onChange={onTextFieldChanged}
                                required={false}
                                validateOnFocusIn={true}
                                validateOnFocusOut={true}
                                errorMessage={errors.evaluationMethod}
                                value={model.evaluationMethod || ""}
                            />
                            
                        </FormControl>

                        <FormControl>

                            <TextField
                                aria-required={false}
                                className="textField"
                                label={localize("PLANNER_MEASURABLE_RESULTS")}
                                name="results"
                                onChange={onTextFieldChanged}
                                required={false}
                                validateOnFocusIn={true}
                                validateOnFocusOut={true}
                                errorMessage={errors.results}
                                value={model.results || ""}
                            />
                            
                        </FormControl>
                        

                    </ExpandableFormField>
                    
                    <ExpandableFormField title={localize("PLANNER_RESOURCES_BUDGET")}>

                        <FormControl>

                            <Dropdown
                                label={localize("PLANNER_RESOURCES")}
                                multiSelect={true}
                                onChange={onResourcesChanged}
                                options={resourceOptions}
                                selectedKeys={model.requiredResources} />

                        </FormControl>

                        <FormControl>
                            <TextField
                                aria-required={false}
                                className="textField"
                                label={localize("PLANNER_BUDGET")}
                                multiline={false}
                                name="budget"
                                onChange={onTextFieldChanged}
                                required={false}
                                validateOnFocusIn={true}
                                validateOnFocusOut={true}
                                errorMessage={errors.budget}
                                value={model.budget || ""} />
                        </FormControl>

                        <FormControl label={localize("PLANNER_BUDGET_APPROVED")}>

                            <Checkbox
                                checked={model.budgetApproved ?? false}
                                name="budgetApproved"
                                label={localize("PLANNER_BUDGET_APPROVED")}
                                onChange={onCheckBoxChanged} />

                        </FormControl>

                        <FormControl>
                            <TextField
                                aria-required={false}
                                className="textField"
                                label={localize("PLANNER_COST_CENTER")}
                                multiline={true}
                                name="costCenter"
                                onChange={onTextFieldChanged}
                                required={false}
                                validateOnFocusIn={true}
                                validateOnFocusOut={true}
                                errorMessage={errors.costCenter}
                                value={model.costCenter || ""} />
                        </FormControl>

                    </ExpandableFormField>
                    
                    <ExpandableFormField title={localize("PLANNER_APPROVAL")}>

                        <FormControl>
                            <FormTokenizedObjectInput
                                items={!model.approvedBy ? [] : [{
                                    key: model.approvedBy.id,
                                    name: model.approvedBy.name,
                                    imageUrl: model.approvedBy.imageUrl,
                                    subText: model.approvedBy.subText,
                                }]}
                                itemLimit={1}
                                label={localize("PLANNER_APPROVAL_BY")}
                                onChange={onApprovedByChanged}
                                types={[1]} />
                        </FormControl>

                        <FormControl>
                            
                            <SpintrDatePicker
                                label={localize("PLANNER_APPROVAL_DATE")}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDatePickerLanguageStrings()}
                                formatDate={undefined}
                                placeholder={localize("ValjDatum")}
                                ariaLabel={localize("ValjDatum")}
                                value={model.approvedOn}
                                maxDate={undefined}
                                isRequired={false}
                                aria-required={false}
                                hideTimeSelect={true}
                                onChangeHandler={onApprovalDateChanged} />

                        </FormControl>

                        <FormControl>
                            <TextField
                                aria-required={false}
                                className="textField"
                                label={localize("PLANNER_APPROVAL_COMMENT")}
                                multiline={true}
                                name="approvalComment"
                                onChange={onTextFieldChanged}
                                required={false}
                                validateOnFocusIn={true}
                                validateOnFocusOut={true}
                                errorMessage={errors.approvalComment}
                                value={model.approvalComment || ""} />
                        </FormControl>

                    </ExpandableFormField>

                </PivotItem>
            </Pivot>
        </div>
    );
}

export default PlannerItemForm;
