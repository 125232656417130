import { Checkbox, ChoiceGroup, TextField } from "@fluentui/react";
import React, { useState } from "react";
import { IAcademyQuestion, IAcademyQuestionAlternative } from "src/academy/types";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./AcademyTestQuestion.scss";

interface IProps {
    index: number;
    question: IAcademyQuestion;
    onUpdate: (updatedQuestion: IAcademyQuestion) => void;
    disabled: boolean;
    isSubmitting: boolean;
    isTask?: boolean;
}

const AcademyTestQuestionResultIcon = (props: IProps) => {
    if (props.isSubmitting && !props.question.answer?.validationResult) {
        return (
            <div className="validation-loader">
                <SpintrLoader loaderSize="small" />
            </div>
        )
    }

    if (props.question.answer?.isValidated) {
        return (
            <div className="validation-reslut">
                <Visage2Icon
                    icon={props.question.answer.validationResult ? "tick-circle" : "close-circle"}
                    color={props.question.answer.validationResult ? "green" : "red"}
                />
            </div>
        )
    }

    return null;
}

const AcademyTestQuestion = (props: IProps) => {
    const [hasAlternatives] = useState<boolean>(props.question.alternatives.length > 0);
    const [checked, setChecked] = useState<number[]>([]);

    return (
        <div className="AcademyTestQuestion">
            <div className="top-row">
                <Label weight="semi-bold">{props.question.text}</Label>
                <AcademyTestQuestionResultIcon {...props} />
            </div>
            {!props.isSubmitting && props.question.answer?.validationExplanation && (
                <Label color="red" size="body-2">{props.question.answer.validationExplanation}</Label>
            )}
            <div className="answer">
                {hasAlternatives && (
                    <div>
                        {!props.isTask ? (
                            <ChoiceGroup
                                disabled={props.disabled}
                                selectedKey={props.question.answer?.alternatives?.length === 1 ?
                                    props.question.answer.alternatives[0].id.toString() :
                                    undefined}
                                options={props.question.alternatives.map((a: IAcademyQuestionAlternative) => {
                                    return {
                                        key: a.id.toString(),
                                        text: a.text
                                    }
                                })}
                                onChange={(e, v) => {
                                    props.onUpdate({
                                        ...props.question,
                                        answer: {
                                            ...(props.question.answer || {}),
                                            id: 0,
                                            alternatives: [{ id: Number(v.key) }],
                                            isValidated: false
                                        }
                                    });
                                }}
                                required={true}
                                aria-required={true}
                            />
                        ) : (
                            props.question.alternatives.map((a) => (
                                <Checkbox
                                    label={a.text}
                                    onChange={(e, v) => {
                                        let newChecked = [...checked];
                                        if (v) {
                                            newChecked.push(a.id);
                                        } else {
                                            newChecked = newChecked.filter((id) => id !== a.id);
                                        }

                                        setChecked(newChecked);

                                        props.onUpdate({
                                            ...props.question,
                                            answer: {
                                                id: 0,
                                                alternatives: newChecked.map((id) => ({
                                                    id,
                                                })),
                                            }
                                        });
                                    }}
                                />
                            ))
                        )}
                    </div>
                )}
                {!hasAlternatives && (
                    <TextField
                        disabled={props.disabled}
                        value={props.question.answer?.text}
                        className="textField"
                        label={localize("Svar")}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                            props.onUpdate({
                                ...props.question,
                                answer: {
                                    ...(props.question.answer || {}),
                                    id: 0,
                                    text: newValue,
                                    isValidated: false
                                }
                            });
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default AcademyTestQuestion;
