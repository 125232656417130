import React, { Component, ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { connect, MapStateToProps, useDispatch } from "react-redux";
import { IApplicationState } from "src/spintr/index";
import { Label, setResponsiveSearchVisible, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./SpintrHeaderMenu.scss";
import { localize } from "src/l10n";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import { ITextField, TextField } from "@fluentui/react";
import { SpintrTypes } from "src/typings";
import ContentWithUnreadIndicator from "src/ui/components/UnreadIndicator/ContentWithUnreadIndicator";
import { fetchOperatingInfo } from "src/operating-info/actions";
import getLightOrDarkColorBasedOnColor from "src/utils/getLightOrDarkColorBasedOnColor";
import { Style } from "src/ui/helpers";
import { useDebouncedEffect } from "src/utils/useDebouncedEffect";
import SpintrHeaderMenuSearch from "./SpintrHeaderMenuSearch";

interface IOwnProps {
    responsive?: boolean;
}

interface IStateProps {
    operatingInfoEnabled?: boolean;
    operatingInfoCategories?: any;
    unreadCountGroupConversations?: number;
    useColorHeader?: boolean;
    headerColor?: string;
    academyEnabled?: boolean;
    plannerEnabled: boolean;
}

type IProps = IOwnProps & IStateProps & RouteComponentProps

interface IHeaderMenuItem {
    key: string;
    icon: string;
    iconType?: "bold" | "outline" | "custom" | undefined;
    text?: string;
    title: string;
    routes: string[];
    isSearch?: boolean;
    enabled: boolean;
    unreadCount?: number;
    action?: () => void;
}

interface IState {
    items: IHeaderMenuItem[];
    activeIndex: number;
    isMeasuring: boolean;
    itemWidths: number[];
}

const SpintrHeaderMenu = (props: IProps) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const wrapperRef = useRef<HTMLDivElement>(undefined);

    const getItems = () => {
        const items: IHeaderMenuItem[] = [{
            key: "home",
            icon: "home-2",
            text: localize("Hem"),
            title: localize("Hem"),
            routes: ["/"],
            enabled: true
        }, {
            key: "groups",
            icon: "profile-2user",
            text: localize("Grupper"),
            title: localize("Grupper"),
            routes: ["/groups", "/my-groups"],
            unreadCount: props.unreadCountGroupConversations,
            enabled: true,
        }, {
            key: "academy",
            icon: "teacher",
            text: localize("Learning"),
            title: localize("Learning"),
            routes: ["/academy"],
            enabled: props.academyEnabled
        }, {
            key: "operatinginfo",
            icon: "cardiogram",
            iconType: "custom",
            text: localize("Driftinformation"),
            title: localize("Driftinformation"),
            routes: ["/operatinginfo"],
            enabled: props.operatingInfoEnabled,
            unreadCount: props.operatingInfoCategories.length > 0 ? props.operatingInfoCategories[0].items.length : 0
        }, {
            key: "planner",
            icon: "calendar-1",
            text: localize("Planerare"),
            title: localize("Planerare"),
            routes: ["/planner"],
            enabled: props.plannerEnabled,
        }, {
            key: "search",
            icon: "search-normal-1",
            text: localize("Sok"),
            title: localize("Sok"),
            routes: ["/search"],
            action: () => {
                dispatch(setResponsiveSearchVisible(true));
            },
            enabled: props.responsive
        }, {
            key: "search",
            icon: "search-normal-1",
            title: localize("Sok"),
            routes: ["/search"],
            isSearch: true,
            enabled: !props.responsive
        }];
    
        return items.filter(x => x.enabled);
    };

    const [state, setState] = useState<IState>(() => {
        return {
            items: getItems(),
            activeIndex: 0,
            isMeasuring: true,
            itemWidths: []
        }
    });

    useEffect(() => {
        setState((s) => ({
            ...s,
            items: getItems(),
        }));
    }, [props.operatingInfoCategories, props.unreadCountGroupConversations]);

    useEffect(() => {
        let activeIndex = 0;

        for (let i = 0; i < state.items.length; i++) {
            if (state.items[i].routes.length > 0 &&
                state.items[i].routes.some(x => location.pathname.startsWith(x))) {
                activeIndex = i;
            }
        }

        setState((s) => ({
            ...s,
            activeIndex
        }));
    }, [location, state.items]);

    useEffect(() => {
        const intervalRef = setInterval(() => {
            if (!wrapperRef.current) {
                return;
            }
    
            const itemWidths = Array.from(wrapperRef.current.children).map(x => x.clientWidth);

            if (itemWidths.every(x => x === 0 || x === 44)) {
                return;
            }

            clearInterval(intervalRef);
    
            setState((s) => ({
                ...s,
                isMeasuring: false,
                itemWidths,
            }));
        }, 500);

        return () => clearInterval(intervalRef);
    }, [wrapperRef.current]);

    useEffect(() => {
        dispatch(fetchOperatingInfo({
            showDeleted: false,
            typesConfig: "operatingInfoSidebar",
        }));
    }, []);

    const indicatorStyle = useMemo(() => {
        if (props.responsive) {
            let left = 0;

            if (wrapperRef.current) {
                left = Array.from(wrapperRef.current.children)[state.activeIndex].getBoundingClientRect().left;
            }

            return {
                left,
                width: state.itemWidths[state.activeIndex]
            }
        } else {
            let left = 0;

            for (let i = 0; i < state.itemWidths.length; i++) {
                if (i < state.activeIndex) {
                    left += (12 + 44);
                }
            }
    
            if (state.activeIndex > 0) {
                left -= 2;
            }
    
            return {
                left,
                width: state.itemWidths[state.activeIndex]
            }
        }
    }, [state.isMeasuring, state.itemWidths, state.activeIndex]);

    const headerColor = useMemo(() => {
        return props.useColorHeader ? (props.headerColor ?? "#FFFFFF") : "#FFFFFF";
    }, []);

    const inactiveTextColor = useMemo(() => {
        if (headerColor === "#FFFFFF") {
            return Style.getHexFromSpintrColor("grey");
        }

        return getLightOrDarkColorBasedOnColor(headerColor,
            Style.getHexFromSpintrColor("light-grey"),
            Style.getHexFromSpintrColor("dark-grey"));
    }, []);

    const activeTextColor = useMemo(() => {
        if (headerColor === "#FFFFFF") {
            return Style.getHexFromSpintrColor("dark-grey");
        }

        return getLightOrDarkColorBasedOnColor(headerColor,
            Style.getHexFromSpintrColor("light-grey"),
            Style.getHexFromSpintrColor("dark-grey"));
    }, []);

    const classNames = useMemo(() => {
        const result = ["SpintrHeaderMenu"];

        if (state.isMeasuring) {
            result.push("is-measuring");
        }

        return result.join(" ");
    }, [state.isMeasuring]);

    return (
        <div ref={wrapperRef} className={classNames}>
            {state.items.map((item, index) => {
                const classes = ["menu-item"]; 
                const isActive = index === state.activeIndex;

                if (isActive) {
                    classes.push("active");
                }

                if (item.isSearch) {
                    classes.push("search");
                }
            
                return (
                    <UnstyledButton
                        key={index}
                        className={classes.join(" ")}
                        title={item.title}
                        // disabled={isActive}
                        onClick={() => {
                            if (item.action) {
                                return item.action();
                            }

                            if (item.routes.length > 0 && !item.isSearch) {
                                props.history.push(item.routes[0]);
                            }

                            if (item.isSearch) {
                                document.querySelector<HTMLInputElement>(".query-input")?.focus();
                            }

                            setState((s) => ({
                                ...s,
                                activeIndex: index
                            }));
                        }}
                    >
                        <ContentWithUnreadIndicator count={item.unreadCount} margin={8} secondaryPlacement>
                            <Visage2Icon
                                icon={item.icon}
                                type={item.iconType}
                                useStrokeForColor={item.iconType === "custom"}
                                hexColor={isActive ? activeTextColor : inactiveTextColor} />
                        </ContentWithUnreadIndicator>
                        {(isActive || state.isMeasuring || props.responsive) && (
                            <div className="content">
                                {item.text && <Label weight="medium" style={{color: isActive ? activeTextColor : inactiveTextColor}}>{item.text}</Label>}
                                {item.isSearch && <SpintrHeaderMenuSearch />}
                            </div>
                        )}
                    </UnstyledButton>
                )
            })}
            {!state.isMeasuring && (
                <div
                    className="indicator header-gradient-border"
                    style={{
                        width: indicatorStyle.width,
                        left: indicatorStyle.left
                    }}
                >
                    <div className="gradient" />
                </div>
            )}
        </div>
    );
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState>
    = (state) => ({
        plannerEnabled: state.app.items.some(
            (app) => app.enabled && app.id === SpintrTypes.SpintrApp.Planner,
        ),
        operatingInfoEnabled: !!state.instance.get("apps").find((a) => a.id === SpintrTypes.SpintrApp.OperatingInfo && a.enabled),
        operatingInfoCategories: state.operatingInfo.currentCategories,
        unreadCountGroupConversations: state.chat.conversations.hasFetched ?
            (state.chat.conversations.items || []).filter((c: any) => c.unread > 0).length :
            state.chat.conversations.unreadCountGroupConversations,
        useColorHeader: state.instance.get("useColorHeader"),
        headerColor: state.instance.get("headerColor"),
        academyEnabled: state.app.items.some((app) => app.enabled && app.id === SpintrTypes.SpintrApp.Academy)
    });

export default withRouter(connect(mapStateToProps)(SpintrHeaderMenu));