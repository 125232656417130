import React, { ReactElement } from "react";
import { CaptionBolder } from "../Text";
import { SidebarContentBoxProps } from "./SidebarContentBox.types";
import classNames from "classnames";

function SidebarContentBox({ children, className, title }: SidebarContentBoxProps): ReactElement {
    return (
        <section className={classNames("SidebarContentBox", className)}>
            <header className="SidebarContentBox-header">
                <CaptionBolder color="contentLight" letterSpacing="normal">
                    {title}
                </CaptionBolder>
            </header>
            <div className="SidebarContentBox-content">
                {children}
            </div>
        </section>
    );
}

export default SidebarContentBox;
