import React, { ReactElement } from "react";
import { TimelineCategoriesProps } from "./TimelineCategories.types";
import { TimelineCategory } from "../TimelineCategory";

function TimelineCategories(props: TimelineCategoriesProps): ReactElement {
    const {
        axisWidth,
        categories,
        onBarClick,
        onCategoryClick,
        onItemDurationChange,
        onDisplayedItemsChange,
        timelineDuration,
        timelineSize,
        todayTime,
    } = props;

    return (
        <section className="TimelineCategories" role="rowgroup">
            {categories.map((category, index) => {
                const offset = index === 0 ? 0 : categories
                    .slice(0, index)
                    .reduce((acc, category) => {
                        if (!category.expanded) {
                            return acc + 52;
                        }

                        let categoryOffset = acc + 52 + category.itemsDisplayed * 50;

                        if ((category.items || []).length > 3) {
                            categoryOffset += 40;
                        }

                        return categoryOffset;
                    }, 0);

                const cumulativeIndex = categories
                    .slice(0, index)
                    .reduce((acc, category) => acc + 1 + category.itemsDisplayed, 0);

                return (
                    <TimelineCategory
                        axisWidth={axisWidth}
                        category={category}
                        cumulativeIndex={cumulativeIndex}
                        key={index}
                        offset={offset}
                        onBarClick={onBarClick}
                        onCategoryClick={onCategoryClick}
                        onDisplayedItemsChange={onDisplayedItemsChange}
                        onItemDurationChange={onItemDurationChange}
                        timelineDuration={timelineDuration}
                        timelineSize={timelineSize}
                        todayTime={todayTime} />
                );
            })}
        </section>
    );
}

export default TimelineCategories;
